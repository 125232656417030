import React from 'react';

import './Heading.scss';

type HProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLHeadingElement>,
  HTMLHeadingElement
>;

const Heading = React.forwardRef(
  (props: HProps & { level: '1' | '2' | '3' | '4' | '5' | '6' }, ref: any) => {
    const { className, level, ...otherProps } = props;

    const newProps = {
      className: `heading lvl-${level} ${className || ''}`,
      ...otherProps,
    };

    return level === '1' ? (
      <h1 {...newProps} ref={ref} />
    ) : level === '2' ? (
      <h2 {...newProps} ref={ref} />
    ) : level === '3' ? (
      <h3 {...newProps} ref={ref} />
    ) : level === '4' ? (
      <h4 {...newProps} ref={ref} />
    ) : level === '5' ? (
      <h5 {...newProps} ref={ref} />
    ) : level === '6' ? (
      <h6 {...newProps} ref={ref} />
    ) : null;
  }
);

export const H = {
  H1: React.forwardRef((props: HProps, ref: any) => (
    <Heading ref={ref} {...props} level='1' />
  )),
  H2: React.forwardRef((props: HProps, ref: any) => (
    <Heading ref={ref} {...props} level='2' />
  )),
  H3: React.forwardRef((props: HProps, ref: any) => (
    <Heading ref={ref} {...props} level='3' />
  )),
  H4: React.forwardRef((props: HProps, ref: any) => (
    <Heading ref={ref} {...props} level='4' />
  )),
  H5: React.forwardRef((props: HProps, ref: any) => (
    <Heading ref={ref} {...props} level='5' />
  )),
  H6: React.forwardRef((props: HProps, ref: any) => (
    <Heading ref={ref} {...props} level='6' />
  )),
};
