import useSwr from 'swr';

import { Api } from 'domain/core';
import {
  Cardinality,
  ContractTemplate,
  ContractType,
  CreateContractDraft,
  CreateContractDraftResponse,
  ListContract,
  RootContract,
} from 'domain/models/contract.model';
import { Page, PageQuery, ResponseAsArray } from 'domain/models';
import { toQueryParams } from 'domain/utils';
import {
  BaseFetcher,
  noRevalidationOptions,
  SWRBaseFetcher,
} from './BaseFetcher';
import { ContractsFilter } from 'pages/app/contracts/ContractsListFilter';

export const useContracts = (
  pageQuery: PageQuery = {
    page: 0,
    size: 10,
  },
  filter: ContractsFilter
): ResponseAsArray<Page<ListContract>> => {
  const queryString = toQueryParams({ ...filter, ...pageQuery });

  const url = queryString
    ? `${Api.baseUrl}/contracts?${queryString}`
    : `${Api.baseUrl}/contracts`;

  const { data, isValidating, error, mutate, revalidate } = useSwr<
    Page<ListContract>
  >(
    // `/data/contracts_${pageInfo.number}.mock.json`
    url
  );

  return [data as Page<ListContract>, isValidating, error, mutate, revalidate];
};

export const useContract = (id?: number): ResponseAsArray<ListContract> => {
  const { data, isValidating, error, mutate, revalidate } =
    useSwr<ListContract>(
      // `/data/contract.mock.json`
      id ? `${Api.baseUrl}/contracts/${id}` : null
    );
  return [data as ListContract, isValidating, error, mutate, revalidate];
};

export const useContractList = (): ResponseAsArray<RootContract[]> => {
  const { data, isValidating, error, mutate, revalidate } = useSwr<
    RootContract[]
  >(`${Api.baseUrl}/contracts/list`);
  return [data as RootContract[], isValidating, error, mutate, revalidate];
};

export const createNewContract = (
  draft: CreateContractDraftResponse
): Promise<{ id: number }> => {
  return new SWRBaseFetcher().make(`${Api.baseUrl}/contracts`, {
    method: 'POST',
    body: JSON.stringify(draft),
  });
};

export const uploadNewContract = (file: File): Promise<Response> => {
  const formData = new FormData();
  formData.append('contract', file);
  return new BaseFetcher().make(`${Api.baseUrl}/contracts/xlsx`, {
    method: 'POST',
    body: formData,
  });
};

export const deleteContract = (id: number): Promise<Response> => {
  return new BaseFetcher().make(`${Api.baseUrl}/admin/contracts/${id}`, {
    method: 'DELETE',
  });
};

export const useContractTemplates = (): ResponseAsArray<ContractTemplate[]> => {
  const { data, isValidating, error, mutate, revalidate } = useSwr(
    // '/data/contract_template.mock.json'
    `${Api.baseUrl}/rest/contracts_templates`,
    noRevalidationOptions
  );

  return [data, isValidating, error, mutate, revalidate];
};

export const useEnabledContractTemplates = (): ResponseAsArray<
  ContractTemplate[]
> => {
  const { data, isValidating, error, mutate, revalidate } = useSwr(
    // '/data/contract_template.mock.json'
    `${Api.baseUrl}/rest/contracts_templates/search/findByDisabledFalse`,
    noRevalidationOptions
  );

  return [data, isValidating, error, mutate, revalidate];
};

export const updateContractTemplate = async (
  contractTemplate: ContractTemplate
): Promise<ContractTemplate> => {
  const url = `${Api.baseUrl}/rest/contracts_templates/${contractTemplate.id}`;
  await new SWRBaseFetcher().make(url, {
    method: 'PATCH',
    body: JSON.stringify(contractTemplate),
  });
  return contractTemplate;
};

export const deleteContractTemplate = async (
  contractTemplate: ContractTemplate
): Promise<any> => {
  const url = `${Api.baseUrl}/rest/contracts_templates/${contractTemplate.id}`;
  return await new BaseFetcher().make(url, {
    method: 'DELETE',
  });
};

export const createContractTemplate = async (
  contractTemplate: ContractTemplate
): Promise<ContractTemplate> => {
  const url = `${Api.baseUrl}/rest/contracts_templates`;
  return await new SWRBaseFetcher().make(url, {
    method: 'POST',
    body: JSON.stringify(contractTemplate),
  });
};

export const useContractDraft = (
  draftData: CreateContractDraft | undefined
): ResponseAsArray<CreateContractDraftResponse> => {
  // const { data, isValidating, error } = useSwr('/data/contract-draft.mock.json')
  const { data, isValidating, error } = useSwr(
    draftData ? `${Api.baseUrl}/contracts/draft` : null,
    {
      fetcher: (url) =>
        new SWRBaseFetcher().make(url, {
          method: 'POST',
          body: JSON.stringify(draftData),
        }),
    }
  );

  return [data as CreateContractDraftResponse, isValidating, error];
};

export const useContractTypes = (): ResponseAsArray<ContractType[]> => {
  const { data, isValidating, error, mutate, revalidate } = useSwr(
    // '/data/contract_template.mock.json'
    `${Api.baseUrl}/contracts/types`,
    noRevalidationOptions
  );

  return [data as ContractType[], isValidating, error, mutate, revalidate];
};

export const useCardinality = (): ResponseAsArray<Cardinality[]> => {
  const { data, isValidating, error, mutate, revalidate } = useSwr(
    `${Api.baseUrl}/contracts/deliverables/cardinality`,
    noRevalidationOptions
  );

  return [data as ContractType[], isValidating, error, mutate, revalidate];
};

export const downloadContractXLS = (contractId: number) => {
  return new BaseFetcher().make(`${Api.baseUrl}/contracts/${contractId}/xlsx`, {
    method: 'GET',
    headers: {
      accept: 'application/vnd.ms-excel',
    },
  });
};
