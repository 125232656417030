import React from 'react';

import ReactTooltip from 'react-tooltip';
import {
  faEye,
  faDownload,
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Skeleton from 'react-loading-skeleton';

import { format, compareDesc, compareAsc } from 'date-fns';

import { Column, Grid, GridItem, Pagination, Paragraph, Row } from 'components';

import {
  ActionCellValue,
  ActionIconContainer,
  DefaultCellValue,
  HeaderField,
  List,
  ListElementRenderer,
  ListHeader,
  NoElements,
} from './list';

import { Page, SortInfo, Version } from 'domain/models';
import { range } from 'domain/utils';

import './DocumentsList.scss';
import { useTranslation } from 'react-i18next';

const LIST_HEADERS = [
  {
    label: 'Document Name',
    code: 'id',
    sortable: false,
    col: 4,
  },
  {
    label: 'Creation date',
    code: 'created',
    col: 6,
    sortable: true,
  },
  {
    label: 'Download',
    code: 'download',
    col: 2,
    sortable: false,
  },
  {
    label: 'Versions',
    code: 'viewversions',
    col: 2,
    sortable: false,
  },
];

interface DocumentsListProps {
  documents: Page<Version>;
  setPage(pageNumber: number): void;
  onPageSizeChange(pageSize: number): void;
  onViewVersions(document: Version): void;
  onViewClick(document: Version): void;
  onDownloadClick(document: Version): void;
}
export const DocumentsList = (props: DocumentsListProps) => {
  const [sortInfo, setSortInfo] = React.useState<SortInfo>();

  const { t } = useTranslation('deliverable');

  const sortedVersions = React.useMemo(() => {
    if (sortInfo?.sortField && sortInfo.sortDir) {
      return props.documents.content.sort((d1, d2) => {
        if (sortInfo.sortField === 'id') {
          if (sortInfo.sortDir === 'ASC') {
            return d1.id - d2.id;
          } else {
            return d2.id - d1.id;
          }
        } else {
          if (sortInfo.sortDir === 'ASC') {
            return compareAsc(new Date(d1.created), new Date(d2.created));
          } else {
            return compareDesc(new Date(d1.created), new Date(d2.created));
          }
        }
      });
    }
    return props.documents.content;
  }, [props.documents, sortInfo]);

  const columnRenderer: ListElementRenderer = (
    field: HeaderField,
    element: Version
  ) => {
    switch (field.code) {
      case 'id': {
        return (
          <DefaultCellValue>
            {element.documentLabel || t('deliverable:documents.untitled')}
          </DefaultCellValue>
        );
      }
      case 'created': {
        const date = format(new Date(element.created), 'dd/MM/yyyy HH:mm:ss');
        return <DefaultCellValue title={date}>{date}</DefaultCellValue>;
      }
      case 'download': {
        return (
          <>
            <Column className='download-wrapper'>
              <ActionCellValue className='download'>
                <ReactTooltip
                  id={`download-tooltip-${element.id}`}
                  className='tooltip'
                  place='bottom'
                  effect='solid'
                >
                  {t('deliverable:documents.download.version')}
                </ReactTooltip>
                <ReactTooltip
                  id={`view-tooltip-${element.id}`}
                  className='tooltip'
                  place='bottom'
                  effect='solid'
                >
                  {t('deliverable:documents.view')}
                </ReactTooltip>

                {element.type === 'JSON_FORM' && (
                  <ActionIconContainer
                    data-for={`view-tooltip-${element.id}`}
                    data-tip
                    onClick={() => props.onViewClick(element)}
                  >
                    <FontAwesomeIcon icon={faEye} className='action-icon' />
                  </ActionIconContainer>
                )}
                <ActionIconContainer
                  data-for={`download-tooltip-${element.id}`}
                  data-tip
                  onClick={() => props.onDownloadClick(element)}
                >
                  <FontAwesomeIcon icon={faDownload} className='action-icon' />
                </ActionIconContainer>
              </ActionCellValue>
            </Column>
          </>
        );
      }
      case 'viewversions': {
        return (
          <Column className='view-versions-wrapper'>
            <ActionCellValue className='view-versions'>
              <ReactTooltip
                id={`versions-tooltip-${element.id}`}
                className='tooltip'
                place='bottom'
                effect='solid'
              >
                {t('deliverable:documents.view-version')}
              </ReactTooltip>

              <ActionIconContainer
                data-for={`versions-tooltip-tooltip-${element.id}`}
                data-tip
                onClick={() => props.onViewVersions(element)}
              >
                <FontAwesomeIcon icon={faArrowRight} className='action-icon' />
              </ActionIconContainer>
            </ActionCellValue>
          </Column>
        );
      }
      default:
        return null;
    }
  };

  return (
    <Column className='documents-list-container'>
      <NoElements>
        <DocumentsListsNoElements />
      </NoElements>
      <ListHeader
        fields={LIST_HEADERS}
        sortDir={sortInfo?.sortDir}
        sortField={sortInfo?.sortField}
        onSortChange={setSortInfo}
        className='documents-list-headers'
      />
      <List
        fields={LIST_HEADERS}
        data={sortedVersions}
        decorator={false}
        columnRenderer={columnRenderer}
      />
      <Pagination
        {...props.documents}
        page={props.documents.number}
        onPageChange={props.setPage}
        onPageSizeChange={props.onPageSizeChange}
      />
    </Column>
  );
};

export const DocumentsListSkeleton = () => {
  return (
    <Column className='task-documents-container'>
      <Column className='task-document-list'>
        {range(0, 6).map((v) => (
          <Grid key={v} className='task-documents-row'>
            <GridItem className='task-document id' col={4}>
              <Skeleton width={30} height={30} />
            </GridItem>
            <GridItem className='task-document date' col={6}>
              <Skeleton width={200} height={30} />
            </GridItem>
            <GridItem className='task-document download' col={2}>
              <Skeleton width={30} height={30} />
            </GridItem>
          </Grid>
        ))}
      </Column>
    </Column>
  );
};

export const DocumentsListsNoElements = () => {
  const { t } = useTranslation('deliverable');
  return (
    <Row className='task-documents-no-elements'>
      <Paragraph>{t('deliverable:documents.no-documents')}</Paragraph>
    </Row>
  );
};
