import { JsonDocumentPage } from "domain/models";
import React from "react";

import './CDSTable.scss';

export const CdsTable = ({ pages }: { pages: JsonDocumentPage[] }) => {

  const data = React.useMemo(() => generateCDSTable(pages), [ pages ]);
  
  return (<>
    { /* Same as Data Description: https://cds.climate.copernicus.eu/cdsapp#!/dataset/reanalysis-era5-single-levels-monthly-means?tab=overview */}
    <table className='cds-table'>
      <thead>
        <tr>
          <th colSpan={2}>Data Description</th>
        </tr>
      </thead>
      <tbody>
        { data?.map(entry => {
          return (<tr key={`data-entry-${entry.label}`}>
              <td className='label'>{ entry.label }</td>
              <td>{ entry.value || '-' }</td>
            </tr>)
        })}
      </tbody>
    </table>
  </>)
};
function generateCDSTable(pages: JsonDocumentPage[]) {

  interface CdsTableMapping {
    label: string;
    location: {
      title: string;
      path: string;
      formatter?(data: any): string;
    }
  }

  const MAPPINGS: CdsTableMapping[] = [{
    label: 'Data type',
    location: {
      title: 'Scientific information',
      path: 'dataType.dataType',
    },
  },
  {
    label: 'Projection',
    location: {
      title: 'Scientific information',
      path: 'dataType.projection',
    },
  },
  {
    label: 'Horizontal coverage',
    location: {
      title: 'Scientific information',
      path: 'spatialProperties.horizontalCoverage',
    },
  },
  {
    label: 'Horizontal resolution',
    location: {
      title: 'Scientific information',
      path: 'spatialProperties.horizontalResolution',
    },
  },
  {
    label: 'Temporal coverage',
    location: {
      title: 'Scientific information',
      path: 'timeProperties',
      formatter(data: { temporalCoverage: { from: string, to: string } }[]) {
        if (data === undefined) {
          return '';
        }
        
        return data.map(d => `${d.temporalCoverage.from} - ${d.temporalCoverage.to}`).join("\n");
      },
    },
  },
  {
    label: 'Temporal resolution',
    location: {
      title: 'Scientific information',
      path: 'timeProperties',
      formatter(data: { temporalResolution: string }[]) {
        if (data === undefined) {
          return '';
        }
        
        return data.map(d => `${d.temporalResolution}`).join("\n");
      },
    },
  },
  {
    label: 'File format',
    location: {
      title: 'CDS Registration',
      path: 'fileType',
    },
  },
  {
    label: 'Update frequency',
    location: {
      title: 'CDS Registration',
      path: 'timeliness',
    },
  }
  ]

  return MAPPINGS.map(mapping => {
    const location = mapping.location;
    const page = pages.find(page => page.title === location.title);
    const paths = location.path.split('.');
    if (page === undefined) {
      return {
        label: mapping.label,
        value: '',
      };
    }
    let value: any = page.value;
    for (const path of paths) {
      const currentPage = value as any;
      if (currentPage === undefined) {
        value = undefined;
        break;
      }
      if (currentPage[path] !== undefined) {
        value = currentPage[path];
      } else {
        value = undefined;
        break;
      }
    }
    
    return {
      label: mapping.label,
      value: mapping.location.formatter ? mapping.location.formatter(value) : value,
    };

  });
}

