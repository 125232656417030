import React from 'react';
import { Row } from './Flex';

import './SimpleTabs.scss';

type SimpleTab = {
  code: string;
  label: string | React.ReactElement;
};
interface SimpleTabsProps {
  tabs: SimpleTab[];
  selectedTab: string;
  tabWidth?: number;
  onTabClick(tabCode: string): void;
}
export const SimpleTabs = ({
  tabs,
  selectedTab,
  tabWidth = 300,
  onTabClick,
}: SimpleTabsProps) => {
  const activeIndex = React.useMemo(() => {
    return tabs.findIndex((t) => t.code === selectedTab);
  }, [tabs, selectedTab]);
  return (
    <Row className='simple-tabs-container'>
      <Row className='simple-tabs'>
        <div
          className='tab-selected-bg'
          key='tabs-bg'
          style={{
            width: tabWidth,
            transform: `translateX(${tabWidth * activeIndex}px)`,
          }}
        />
        {tabs.map((tab) => (
          <Row
            className={`tab ${selectedTab === tab.code ? 'active' : ''}`}
            key={tab.code}
            onClick={() => onTabClick(tab.code)}
            style={{
              width: tabWidth,
            }}
          >
            {tab.label}
          </Row>
        ))}
      </Row>
    </Row>
  );
};
