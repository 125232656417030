import React, { ReactNode } from 'react';

import { useSelector } from 'react-redux';

import { Role, AppStore } from 'domain/models';

import { isEqualOrHigherTierByKey } from 'domain/utils';

export interface WithRoleProps {
  role: Role;
  exact?: boolean;
  action?: 'hide' | 'disable' | 'destroy';
  showOverlay?: boolean;
}

export interface ConditionalWithRoleProps extends WithRoleProps {
  children: ReactNode,
  fallback: ReactNode,
}

export const ConditionalWithRole = (  (
    {
      exact = false,
      role,
      children,
      fallback, } : ConditionalWithRoleProps ) => {
    
    const userRoles = useSelector((store: AppStore) => store.auth.user?.roles);
    
    if (userRoles && isEqualOrHigherTierByKey(userRoles, role, exact)) {
      return <React.Fragment>{children}</React.Fragment>;
    } else {
      return <React.Fragment>{fallback}</React.Fragment>
    }
  }
);


export const WithRole = React.forwardRef<any,React.PropsWithChildren<WithRoleProps>>(
  (
    {
      action = 'hide',
      exact = false,
      role,
      children
    }: React.PropsWithChildren<WithRoleProps>,
    ref: any
  ) => {
    
    const userRoles = useSelector((store: AppStore) => store.auth.user?.roles);
    
    if (userRoles && isEqualOrHigherTierByKey(userRoles, role, exact)) {
      return children;
    } else {
      switch (action) {
        case 'destroy':
          return null;
        case 'disable':
          return React.Children.map(children, (child: any) =>
            React.cloneElement(child, {
              className: `${child.className || ''} disabled-by-tier`
            })
          ) as any;
        case 'hide':
        default:
          return React.Children.map(children, (child: any) =>
            React.cloneElement(child, {
              style: { visibility: 'hidden' }
            })
          ) as any;
      }
    }
  }
);
