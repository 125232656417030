import { AuthorizedService } from 'domain/services/authorized-service.base';

export const noRevalidationOptions = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

export class SWRBaseFetcher extends AuthorizedService {
  public make(
    resource: RequestInfo,
    init: RequestInit | undefined = { headers: {} }
  ) {
    // Timeout para comprobar los skeletons
    const t = new Promise((res) =>
      setTimeout(() => {
        res(null);
      }, 2000)
    );
    // return t.then(() => this.fetch(resource, init).then((r) => r.json()));
    // Petición sin timeout
    return this.fetch(resource, init)
      .then((r) => {
        return r.json()
      });
  }
}
export class BaseFetcher extends AuthorizedService {
  public make(
    resource: RequestInfo,
    init: RequestInit | undefined = { headers: {} }
  ) {
    return this.fetchRaw(resource, init);
  }
}
