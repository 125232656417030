export const Api = {
  baseUrl:
    process.env.REACT_APP_BASE_URL ||
     (process.env.NODE_ENV === 'development'
      ? 'https://cop77-dev-339ea00c.nip.io'
      : (process.env.NODE_ENV === 'test'
        ? 'https://cop77-dev-339ea00c.nip.io'
        : window.location.origin)),
  contact: '/newsletter/subscribe',
  auth: {
    login: '/auth/signin',
    confirm: '/auth/confirm',
    lostPassword: '/auth/lost-password',
    renew: '/auth/renew',
  },
  contracts: {
    getContractTemplates: '/rest/contracts_templates',
    getDeliverablesTemplates: '/rest/deliverables_templates',
  },
};
