import { JSONSchema7 } from 'json-schema'

export enum ContentType {
  XLSX,
  DOCX,
  PDF,
  ZIP,
  JSON,
}

export interface Document {
  id: number;
  contentId: string;
  mimeType: string;
  description: string;
  created: Date;
  contentLength: number;
  lockOwner: string;
  ancestorId: number;
  ancestralRootId: number;
  successorId: number;
  version: string;
  label: string;
  contentType: ContentType;
}

export type DocumentType = 'JSON_FORM' | 'DOCUMENT';

export interface Version {
  id: number;
  created: string; /* iso8601 */
  documentId: string;
  documentLabel: string;
  type: DocumentType;
}
export interface JsonDocument {
  content: { 
    [key: string]: JsonDocumentContent
  };
}

export interface JsonDocumentContent {
  schema: JSONSchema7;
  value: any;
}

export interface JsonDocumentPage extends JsonDocumentContent {
  title: string;
}
