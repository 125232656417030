import React from 'react';

import { useSelector } from 'react-redux';

import { Column, DataLink, Input, Pagination, Row, Select } from 'components';

import {
  DefaultCellValue,
  HeaderField,
  List,
  ListElementRenderer,
  ListHeader,
  ListWrapper,
  NoElements,
} from 'pages/shared';
import { BaseWidget, BaseWidgetTitle } from '../BaseWidget';

import { WidgetProps } from '../widget.model';
import { useContractList, useDeliverables, useECVs } from 'domain/swr';
import { asSelect } from 'domain/utils';
import {
  AppStore,
  Deliverable,
  DeliverableType,
  PageQuery,
  SortDirection,
} from 'domain/models';

import './CDSDeliverable.scss';
import { DashOnEmpty } from 'pages/shared/DashOnEmpty';
import { useTranslation } from 'react-i18next';

const headers: HeaderField[] = [
  {
    code: 'name',
    label: 'Name',
    col: 3,
    sortable: true,
  },
  {
    code: 'deadline',
    label: 'Deadline',
    col: 2,
    sortable: true,
  },
  {
    code: 'endDate',
    label: 'End Date',
    col: 2,
    sortable: true,
  },
  {
    code: 'root_id',
    label: 'Group',
    col: 2,
    sortable: true,
  },
  {
    code: 'state',
    label: 'State',
    col: 2,
    sortable: true,
  },
  {
    code: 'cds-dataset',
    label: 'CDS Dataset',
    col: 2,
    sortable: false,
  },
  {
    code: 'ecv',
    label: 'ECVs',
    col: 3,
    sortable: true,
  },
  {
    code: 'type',
    label: 'Type',
    col: 2,
    sortable: true,
  },
];

export const CDSDeliverableWidget = (props: WidgetProps) => {
  const selectedContract = useSelector(
    (store: AppStore) => store.root.rootContract
  );

  const [selectedGroup, setSelectedGroup] = React.useState<string | undefined>();

  const [selectedECVs, setSelectedECVs] = React.useState<
    { value: number; label: string }[]
  >([]);

  const [selectedTypes, setSelectedTypes] = React.useState<
    { value: string; label: string }[]
  >([]);

  const [sort, setSort] = React.useState<
    { code: string; direction: SortDirection } | undefined
  >({ code: 'name', direction: 'ASC' });

  const [pageQuery, setPageQuery] = React.useState<PageQuery>({
    page: 0,
    size: 5,
  });

  /* Go to first page when these two changes */
  React.useEffect(() => {
    setPageQuery({ ...pageQuery, page: 0 });
  }, [selectedGroup, selectedContract, selectedECVs, selectedTypes]);

  const deliverableFilter = React.useMemo(() => {
    return {
      contractId: selectedContract?.id,
      ecv: selectedECVs.map((ecv) => ecv.value),
      types: selectedTypes.map((type) => type.value),
      rootId: selectedGroup,
      sort: sort ? `${sort.code},${sort.direction.toUpperCase()}` : undefined,
    };
  }, [selectedContract, selectedECVs, selectedTypes, sort, selectedGroup]);

  const [ecvs] = useECVs({ page: 0, size: 999 });
  const [deliverables] = useDeliverables(deliverableFilter, pageQuery);
  const [contractList] = useContractList();

  const ecvOptions = React.useMemo(() => asSelect<number>(ecvs), [ecvs]);
  const typeOptions = [
    DeliverableType.DOCUMENTATION,
    DeliverableType.DATASET,
  ].map((d) => ({ label: d, value: d }));

  const columnRenderer: ListElementRenderer = (
    field: HeaderField,
    element: Deliverable
  ) => {
    switch (field.code) {
      case 'name':
        return (
          <DefaultCellValue title={element.name}>
            <DataLink
              relPath={`/app/contract/${element.contract}/deliverable/${element.id}`}
            >
              {element.name}
            </DataLink>
          </DefaultCellValue>
        );
      case 'cds-dataset':
        const deliverableContract = contractList.find(
          (c) => c.id === element.contract
        );

        return (
          <DefaultCellValue title={deliverableContract?.name ?? '-'}>
            {deliverableContract ? (
              <DataLink relPath={`/app/contract/${deliverableContract.id}`}>
                {deliverableContract.name}
              </DataLink>
            ) : (
              '-'
            )}
          </DefaultCellValue>
        );

      case 'state':
        return <DefaultCellValue title={element.state}>
          <DashOnEmpty text={element.state} />
        </DefaultCellValue>;

      case 'endDate':
        return <DefaultCellValue title={element.endDate}>
          <DashOnEmpty text={element.endDate} />
        </DefaultCellValue>;

      case 'deadline':
        return <DefaultCellValue title={element.deadline}>{element.deadline}</DefaultCellValue>;

      case 'root_id':
        return <DefaultCellValue title={element.rootId.toString()}>
          <span className='pointer' onClick={() => setSelectedGroup(selectedGroup ? undefined : element.rootId.toString())}>
            {element.rootId.toString()}
          </span>
        </DefaultCellValue>;

      case 'ecv':
        const ecvs = element.ecvs.map((ecv) => ecv.name).join(', ');
        return <DefaultCellValue title={ecvs}>{ecvs}</DefaultCellValue>;
        
      case 'type':
        return (
          <DefaultCellValue title={element.type}>
            {element.type ?? '-'}
          </DefaultCellValue>
        );
      default:
        return null;
    }
  };

  return (
    <BaseWidget {...props}>
      <BaseWidgetTitle content='CDS Deliverables' />
      <Column className='cds-deliverables-container'>
        <Row className='cds-deliverables-selects'>
          <Select
            isMulti
            placeholder='Select type...'
            className='type-select'
            options={typeOptions}
            value={selectedTypes}
            onChange={setSelectedTypes}
          />
          <Select
            isMulti
            placeholder='Select ECV...'
            className='ecv-select'
            options={ecvOptions}
            value={selectedECVs}
            onChange={setSelectedECVs}
          />
          <GroupSelection selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup} />
        </Row>

        <Column className='cds-deliverables-wrapper'>
          {deliverables?.content !== undefined ? (
            <>
              <ListWrapper>
                <NoElements>
                  There are no deliverables that matches current filter
                </NoElements>

                <ListHeader
                  fields={headers}
                  sortDir={sort?.direction}
                  sortField={sort?.code}
                  onSortChange={(sort) => {
                    setSort(
                      sort.sortField && sort.sortDir
                        ? { code: sort.sortField, direction: sort.sortDir }
                        : undefined
                    );
                  }}
                />
                <List
                  className='widget-list'
                  fields={headers}
                  decorator={false}
                  data={deliverables.content}
                  columnRenderer={columnRenderer}
                />

                <Pagination
                  totalPages={deliverables.totalPages}
                  page={pageQuery.page}
                  onPageChange={(page: number) => {
                    setPageQuery({
                      ...pageQuery,
                      page: page,
                    });
                  }}
                  onPageSizeChange={(pageSize: number) =>
                    setPageQuery({ ...pageQuery, size: pageSize })
                  }
                />
              </ListWrapper>
            </>
          ) : (
            <div className='no-deliverables'>
              There are no deliverables that matches current filter
            </div>
          )}
        </Column>
      </Column>
    </BaseWidget>
  );
};

const GroupSelection = ({ selectedGroup, setSelectedGroup }: { selectedGroup: string | undefined, setSelectedGroup: (group: string | undefined) => void }) => {
  
  const { t } = useTranslation(['common', 'deliverables']);
  const cleanGroup = () => setSelectedGroup(undefined);

  return <div className='group-selection'>
      { (selectedGroup) ? 
       <>
        <span className='group-selection-label'>{t('deliverables:list.cds.filter.group.title')}:</span>
        <span className='group-selection-value'>
          <span className='group-selection-value-text'>{selectedGroup}</span>
          <span className='group-selection-value-clean' onClick={cleanGroup}>&times;</span></span>
      </> :
        <span className='group-selection-placeholder'>{t('deliverables:list.cds.filter.group.noGroupSelected')}</span> }
    </div>
};
