import useSwr, { mutate } from 'swr';

import { Api } from 'domain/core';

import {
  APIError,
  Deliverable,
  DeliverableTemplate,
  HistoryTask,
  JsonDocument,
  Page,
  PageQuery,
  ResponseAsArray,
  Version,
} from 'domain/models';
import { BaseFetcher, SWRBaseFetcher } from './BaseFetcher';
import { toQueryParams } from 'domain/utils';
import { DeliverablesFilter } from 'pages/app/deliverables/DeliverablesListFilter';

export const useDeliverablesTemplates = (
  contractId?: number
): ResponseAsArray<DeliverableTemplate[]> => {
  const url = `${Api.baseUrl}/rest/deliverables_templates/search/findByContract?contract=/rest/contracts_templates/${contractId}`;
  const { data, isValidating, error, mutate, revalidate } = useSwr(
    contractId ? url : null
  );

  return [data || [], isValidating, error, mutate, revalidate];
};

export const useDeliverableTemplate = (
  templateId?: number | null
): ResponseAsArray<DeliverableTemplate> => {
  const url =
    templateId === undefined || templateId === null
      ? null
      : `${Api.baseUrl}/rest/deliverables_templates/${templateId}`;
  const { data, isValidating, error, mutate } = useSwr(url);

  // mutate(updateDeliverableTemplate)

  return [data, isValidating, error, mutate];
};

export const updateDeliverableTemplate = async (
  template: DeliverableTemplate
): Promise<DeliverableTemplate> => {
  const url = `${Api.baseUrl}/rest/deliverables_templates/${template.id}`;
  await new SWRBaseFetcher().make(url, {
    method: 'PATCH',
    body: JSON.stringify(template),
  });
  return template;
};

export const deleteDeliverableTemplate = async (
  deliverableTemplate: DeliverableTemplate
): Promise<boolean> => {
  const url = `${Api.baseUrl}/rest/deliverables_templates/${deliverableTemplate.id}`;
  await new BaseFetcher().make(url, {
    method: 'DELETE',
  });
  return true;
};

export const createDeliverableTemplate = async (
  contractTemplateId: number,
  deliverableTemplate: DeliverableTemplate
): Promise<DeliverableTemplate> => {
  const contractTemplateURL = `/rest/contract_templates/${contractTemplateId}`;
  const url = `${Api.baseUrl}/rest/deliverables_templates`;
  const savedDeliverable = await new SWRBaseFetcher().make(url, {
    method: 'POST',
    body: JSON.stringify({
      ...deliverableTemplate,
      contract: contractTemplateURL,
    }),
  });
  return savedDeliverable;
};

export const useContractDeliverables = (
  contractId?: number
): ResponseAsArray<Deliverable[]> => {
  const url = contractId
    ? `${Api.baseUrl}/contracts/${contractId}/deliverables`
    : null;
  const { data, isValidating, error, mutate, revalidate } = useSwr(url);

  return [data as Deliverable[], isValidating, error, mutate, revalidate];
};

export const useContractDeliverable = (
  contractId: number,
  deliverableId: number
): ResponseAsArray<Deliverable> => {
  const url = contractId
    ? `${Api.baseUrl}/contracts/${contractId}/deliverables/${deliverableId}`
    : null;
  const { data, isValidating, error, mutate, revalidate } = useSwr(url);

  return [data as Deliverable, isValidating, error, mutate, revalidate];
};

export const useDeliverables = (
  filter: DeliverablesFilter,
  pageQuery: PageQuery = {
    page: 0,
    size: 10,
  }
): ResponseAsArray<Page<Deliverable>> => {
  const queryString = toQueryParams({ ...filter, ...pageQuery });

  const url = queryString
    ? `${Api.baseUrl}/contracts/deliverables?${queryString}`
    : `${Api.baseUrl}/contracts/deliverables`;

  const { data, isValidating, error, mutate, revalidate } = useSwr(url);

  return [data as Page<Deliverable>, isValidating, error, mutate, revalidate];
};

// For individual deliverable. Not for admin/deliverabes
export const updateDeliverable = (newDeliverable: Deliverable) => {
  const {
    contract,
    document,
    state,
    type,
    workflow,
    contentType,
    id,
    ...data
  } = newDeliverable;
  return mutate(
    `${Api.baseUrl}/contracts/${contract}/deliverables/${id}`,
    async () => {
      return new SWRBaseFetcher().make(
        `${Api.baseUrl}/contracts/${contract}/deliverables/${id}`,
        {
          method: 'PATCH',
          body: JSON.stringify(data),
        }
      );
    },
    true
  );
};

export const patchDeliverable = (newDeliverable: Deliverable) => {
  const { contract, document, state, type, workflow, id, ...data } =
    newDeliverable;
  return new SWRBaseFetcher().make(
    `${Api.baseUrl}/contracts/${contract}/deliverables/${id}`,
    {
      method: 'PATCH',
      body: JSON.stringify(data),
    }
  );
};

export const getDeliverableWorkflow = (
  contractId: number,
  deliverableId: number
): Promise<Blob> => {
  const url = `${Api.baseUrl}/contracts/${contractId}/deliverables/${deliverableId}/workflow/png`;
  return new BaseFetcher().make(url).then((r) => r.blob());
};

export const useDeliverableWorkflowHistory = (
  contractId: number,
  deliverableId: number
): ResponseAsArray<HistoryTask[]> => {
  const url = `${Api.baseUrl}/contracts/${contractId}/deliverables/${deliverableId}/workflow/history`;
  const { data, isValidating, error } = useSwr(url);
  return [data, isValidating, error];
};

export const useDocument = (
  contractId?: number | string,
  deliverableId?: number | string,
  documentId?: string,
  version?: string
): ResponseAsArray<JsonDocument> => {
  const versionQuery = version !== undefined ? `?version=${version}` : '';
  const url = (contractId === undefined) ? null :
    `${Api.baseUrl}/contracts/${contractId}/deliverables/${deliverableId}/document/${documentId}${versionQuery}`;
  const { data, isValidating, error } = useSwr<JsonDocument, APIError>(url);
  return [data as JsonDocument, isValidating, error as APIError];
};

export const useDocuments = (
  contractId: number,
  deliverableId: number,
  pageQuery: PageQuery = {
    page: 0,
    size: 10,
  }
): ResponseAsArray<Page<Version>> => {
  const url = `${
    Api.baseUrl
  }/contracts/${contractId}/deliverables/${deliverableId}/documents?${toQueryParams(
    pageQuery
  )}`;
  const { data, isValidating, error } = useSwr(url);
  return [data as Page<Version>, isValidating, error];
};

export const useDocumentVersions = (
  contractId: number,
  deliverableId: number,
  documentId: string
): ResponseAsArray<Version[]> => {
  const url = `${Api.baseUrl}/contracts/${contractId}/deliverables/${deliverableId}/versions/document/${documentId}`;
  const { data, isValidating, error } = useSwr(url);
  return [data as Version[], isValidating, error];
};

export const downloadDocument = (
  contractId: number,
  deliverableId: number,
  documentId: string,
  versionId?: number
): Promise<{ url: string }> => {
  let url = `${Api.baseUrl}/contracts/${contractId}/deliverables/${deliverableId}/authorize/document/${documentId}`;
  if (versionId) {
    url += `?version=${versionId}`;
  }
  return new SWRBaseFetcher().make(url);
};

export const downloadDocumentZip = (
  contractId: number,
  deliverableId: number,
  versionId?: number
): Promise<{ url: string }> => {
  let url = `${Api.baseUrl}/contracts/${contractId}/deliverables/${deliverableId}/documents/authorize/zip`;
  if (versionId) {
    url += `?version=${versionId}`;
  }
  return new SWRBaseFetcher().make(url);
};

export const uploadDocument = (
  contractId: string,
  deliverableId: string,
  documentId: string,
  documentLabel: string,
  payload: Blob,
  contentType?: string
): Promise<{ id: number; created: string }> => {
  return new SWRBaseFetcher().make(
    `${Api.baseUrl}/contracts/${contractId}/deliverables/${deliverableId}/document/${documentId}?documentLabel=${documentLabel}`,
    {
      method: 'POST',
      body: payload,
      headers: {
        'Content-Type':
          contentType !== undefined
            ? contentType
            : payload.type
            ? payload.type
            : 'application/octet-stream',
      },
    }
  );
};

export const useDeliverablesType = (): ResponseAsArray<string[]> => {
  const { data, isValidating, error } = useSwr(
    `${Api.baseUrl}/contracts/deliverables/types`
  );

  return [data as string[], isValidating, error];
};

export const useDeliverablesCardinality = (): ResponseAsArray<string[]> => {
  const { data, isValidating, error } = useSwr(
    `${Api.baseUrl}/contracts/deliverables/cardinality`
  );

  return [data as string[], isValidating, error];
};
