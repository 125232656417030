import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

import { Column, Grid, GridItem, Row } from 'components';

import { HeaderField } from './ListHeader';
import { DefaultCellValue, ListWrapperContext } from './ListComponents';

import './List.scss';

export type ListElementRenderer = (
  field: HeaderField,
  element: any
) => React.ReactElement | any;

interface ListProps {
  fields: HeaderField[];
  data: any[];
  className?: string;
  decorator: boolean;
  columnRenderer?: ListElementRenderer;
}
/**
 * List component
 * @param
 * @returns
 */
export const List = ({
  data,
  fields,
  className = '',
  decorator = false,
  columnRenderer,
}: ListProps) => {

  const { id } = React.useContext(ListWrapperContext)

  return (
    <Column className={`list ${className}`}>
      <div
        className={`no-elements-container ${
          data?.length > 0 ? 'hide' : 'show'
        }`}
        id={`${id}-no-elements-container`}
      />
      {data && data.map((d, i) => (
        <ListElement
          key={i}
          element={d}
          fields={fields}
          decorator={decorator}
          renderer={columnRenderer}
        />
      ))}
    </Column>
  );
};

interface ListElementProps {
  element: any;
  fields: HeaderField[];
  decorator: boolean;
  renderer?: ListElementRenderer;
}
/**
 * List element responsible of render each list row
 * @param
 * @returns
 */
export const ListElement = ({
  element,
  fields,
  decorator,
  renderer,
}: ListElementProps) => {
  const ElementContent = ({
    field,
    element,
    index,
  }: {
    field: HeaderField;
    element: any;
    index: number;
  }): React.ReactElement => {
    let content;
    if (renderer) {
      content = renderer(field, element);
    }
    if (content) {
      return content;
    } else {
      return (
        <DefaultCellValue title={element[field.code]}>
          {element[field.code] ?? ''}
        </DefaultCellValue>
      );
    }
  };

  const cols = React.useMemo(() => {
    return (
      fields.reduce((acc: number, curr: HeaderField) => acc + curr.col, 0) || 12
    );
  }, [fields]);

  return (
    <Row className='list-element-row'>
      <Grid className='list-element-grid' columns={cols}>
        {decorator && (
          <FontAwesomeIcon icon={faCircle} className='hover-indicator' />
        )}

        {fields.map((field, i) => (
          <GridItem
            className={`element-cell ${field.className ?? ''} ${field.code}`}
            col={field.col}
            key={field.code}
          >
            <ElementContent field={field} element={element} index={i} />
          </GridItem>
        ))}
      </Grid>
    </Row>
  );
};
