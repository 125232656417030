import React from 'react';

import './Flex.scss';

export type DivProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

type FlexProps = DivProps & { col?: number; from?: number };

export const Row = React.forwardRef((props: FlexProps, ref: any) => {
  const { className, col, from = 1, style, ...otherProps } = props;

  const _style = {
    gridColumn: col ? `${from} / span ${col}` : undefined,
    ...style,
  };
  return (
    <div
      style={_style}
      className={`flex-row ${className || ''}`}
      {...otherProps}
      ref={ref}
    >
      {props.children}
    </div>
  );
});

export const Column = React.forwardRef((props: FlexProps, ref: any) => {
  const { className, col, from = 1, style, ...otherProps } = props;

  const _style = {
    gridColumn: col ? `${from} / span ${col}` : undefined,
    ...style,
  };
  return (
    <div
      style={_style}
      className={`flex-col ${className || ''}`}
      {...otherProps}
      ref={ref}
    >
      {props.children}
    </div>
  );
});

type GridProps = React.PropsWithChildren<
  FlexProps & { main?: boolean; columns?: number }
>;
export const Grid = React.forwardRef<
  any,
  GridProps
>(
  (
    props: GridProps,
    ref: any
  ) => {
    const {
      className,
      col,
      from = 1,
      columns = 12,
      main = false,
      style,
      children,
      ...nativeProps
    } = props;

    const gridTemplateColumns = `repeat(${columns}, 1fr)`;
    const _style = {
      gridColumn: col ? `${from} / span ${col}` : undefined,
      gridTemplateColumns,
      ...style,
    };

    return (
      <div
        style={_style}
        className={`grid-container ${main ? 'main' : ''} ${className || ''}`}
        ref={ref}
        {...nativeProps}
      >
        {children}
      </div>
    );
  }
);

export const GridItem = React.forwardRef<
  any,
  React.PropsWithChildren<FlexProps>
>((props: React.PropsWithChildren<FlexProps>, ref: any) => {
  const {
    className = '',
    col = 12,
    from,
    style,
    children,
    ...nativeProps
  } = props;

  const _style = {
    gridColumnStart: from ? from : undefined,
    gridColumnEnd: `span ${col}`,
    ...style,
  };

  return (
    <div
      style={_style}
      className={`grid-item ${className}`}
      ref={ref}
      {...nativeProps}
    >
      {children}
    </div>
  );
});
