import React from 'react';

import { SecurePortal, Row } from 'components';

import './ListComponents.scss';

export const ListWrapperContext = React.createContext<{ id: number | string }>({
  id: Date.now(),
});

/**
 * Element rendered into List component. It automatically hides or shows itself based on data
 * @param
 * @returns
 */
export const NoElements = ({ children }: React.PropsWithChildren<{}>) => {
  const { id } = React.useContext(ListWrapperContext);
  return (
    <SecurePortal id={`${id}-no-elements-container`}>{children}</SecurePortal>
  );
};

interface DefaultCellValueProps {
  title?: string;
  className?: string;
}
/**
 * Element used to wrap the value renderer on each row cell
 * @param
 * @returns
 */
export const DefaultCellValue = ({
  title,
  className = '',
  children,
}: React.PropsWithChildren<DefaultCellValueProps>) => (
  <span className={`element-cell-value ${className}`} title={title}>
    {children}
  </span>
);

interface ActionCellValueProps {
  className?: string;
}
/**
 * Like DefaultCellValue but for actions at the end of the row
 * @param
 * @returns
 */
export const ActionCellValue = ({
  className = '',
  children,
}: React.PropsWithChildren<ActionCellValueProps>) => (
  <Row className={`actions-cell-value ${className}`}>{children}</Row>
);

/**
 * Action icon container. Meant to be renderer inside ActionCellValue
 * @param
 * @returns
 */
export const ActionIconContainer = ({
  className = '',
  onClick,
  children,
  ...extra
}: React.PropsWithChildren<{ className?: string; onClick?: VoidFunction }>) => (
  <Row
    className={`action-icon-container ${className}`}
    onClick={onClick}
    {...extra}
  >
    {children}
  </Row>
);

interface FilterContainerProps {
  show: boolean;
  className?: string;
}
/**
 * Filter container responsible to expand and collapse itself
 * @param
 * @returns
 */
export const FilterContainer = ({
  show,
  className = '',
  children,
}: React.PropsWithChildren<FilterContainerProps>) => {
  return (
    <div className={`filter-container ${className} ${show ? 'show' : 'hide'}`}>
      {children}
    </div>
  );
};

/*
  Component that only renders the children passed as props. It also declare a context to 
  syncronize id dependant elements like NoElements
*/
export const ListWrapper = ({
  children,
  id,
}: React.PropsWithChildren<{ id?: string | number }>) => {
  const _id = React.useMemo(() => id ?? Date.now(), []);

  return (
    <ListWrapperContext.Provider value={{ id: _id }}>
      {children}
    </ListWrapperContext.Provider>
  );
};
