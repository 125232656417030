import { Api } from 'domain/core';
import {
  Page,
  PageQuery,
  ResponseAsArray,
  Role,
  User,
  UserSettings,
  UserUpdate,
} from 'domain/models';
import { toQueryParams } from 'domain/utils';
import {
  AdminDashboardConfiguration,
  DashboardConfiguration,
  WidgetsConfiguration,
} from 'pages/shared/widgets';
import useSwr from 'swr';
import { BaseFetcher, SWRBaseFetcher } from './BaseFetcher';

export const useUsers = (): ResponseAsArray<User[]> => {
  const url = `${Api.baseUrl}/rest/users`;
  const { data, isValidating, error, revalidate } = useSwr(url);

  return [data, isValidating, error, , revalidate];
};

export const useUsersById = (userIds: number[]): ResponseAsArray<User[]> => {
  const url = `${Api.baseUrl}/rest/users/search/findByIdIn?ids=${userIds.join(
    ','
  )}`;
  const { data, isValidating, error } = useSwr(
    userIds && userIds.length > 0 ? url : null
  );

  return [data, isValidating, error];
};

export const getProfile = (): Promise<User> => {
  return new SWRBaseFetcher().make(`${Api.baseUrl}/users/me`);
};

export const updateProfile = (user: UserUpdate) => {
  return new SWRBaseFetcher().make(`${Api.baseUrl}/users/me`, {
    method: 'PATCH',
    body: JSON.stringify(user),
  });
};

export const updateUser = (user: Partial<User>) => {
  const { id, authorities, ...updatableProps } = user;
  return new SWRBaseFetcher().make(`${Api.baseUrl}/rest/users/${user.id}`, {
    method: 'PATCH',
    body: JSON.stringify(updatableProps),
  });
};

export const createUser = (user: Partial<User>) => {
  const { id, ...props } = user;
  return new SWRBaseFetcher().make(`${Api.baseUrl}/admin/users`, {
    method: 'POST',
    body: JSON.stringify(props),
  });
};

export const deleteUser = (user: User) => {
  const { id } = user;

  if (id) {
    return new BaseFetcher().make(`${Api.baseUrl}/admin/users/${id}`, {
      method: 'DELETE',
    });
  } else {
    throw new Error('Cannot delete user');
  }
};

export const useUserSettings = (): ResponseAsArray<UserSettings> => {
  const { data, isValidating, error, mutate, revalidate } = useSwr(
    `${Api.baseUrl}/users/me/settings`
  );
  return [data as UserSettings, isValidating, error, mutate, revalidate];
};

export const getUserSettings = (): Promise<UserSettings> => {
  return new SWRBaseFetcher().make(`${Api.baseUrl}/users/me/settings`);
};

export const updateUserSettings = (configuration: UserSettings) => {
  return new SWRBaseFetcher().make(`${Api.baseUrl}/users/me/settings`, {
    method: 'PATCH',
    body: JSON.stringify(configuration),
  });
};

export const useUserDashboard = (): ResponseAsArray<DashboardConfiguration> => {
  //
  const { data, isValidating, error, mutate, revalidate } = useSwr(
    `${Api.baseUrl}/users/me/dashboard`
  );
  // const {data, isValidating, error} = useSwr(`/data/dashboard-configuration.json`)
  const dashboardConfiguration = data?.dashboard;
  return [dashboardConfiguration, isValidating, error, mutate, revalidate];
};

export const useAdminDashboards = (
  pageInfo: PageQuery = { page: 0, size: 10 }
): ResponseAsArray<Page<AdminDashboardConfiguration>> => {
  const url = `${Api.baseUrl}/admin/users/dashboards?${toQueryParams(
    pageInfo
  )}`;
  const { data, isValidating, error, mutate, revalidate } = useSwr(url);
  return [
    data as Page<AdminDashboardConfiguration>,
    isValidating,
    error,
    mutate,
    revalidate,
  ];
};

export const useAdminDashboard = (
  role: Role | string
): ResponseAsArray<AdminDashboardConfiguration> => {
  const url = `${Api.baseUrl}/admin/users/dashboards/${role}`;

  const { data, isValidating, error, mutate, revalidate } = useSwr(url);

  return [
    initializeAdminDashboard(data) as AdminDashboardConfiguration,
    isValidating,
    error,
    mutate,
    revalidate,
  ];
};

export const putAdminDashboard = (config: AdminDashboardConfiguration) => {
  return new SWRBaseFetcher().make(`${Api.baseUrl}/admin/users/dashboards`, {
    method: 'PUT',
    body: JSON.stringify(config),
  });
};

export const putUserDashboard = (configuration: DashboardConfiguration) => {
  return new SWRBaseFetcher().make(`${Api.baseUrl}/users/me/dashboard`, {
    method: 'PUT',
    body: JSON.stringify({ dashboard: configuration }),
  });
};

const initializeAdminDashboard = (
  dashboard: AdminDashboardConfiguration
): AdminDashboardConfiguration => {
  if (dashboard.dashboardSettings.dashboard.length === 0) {
    return {
      ...dashboard,
      dashboardSettings: {
        dashboard: WidgetsConfiguration.map((wc) => ({
          code: wc.code,
          visible: false,
          active: false,
        })),
      },
    };
  }

  return dashboard;
};
