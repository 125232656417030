import React from 'react';

import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { AppStore } from 'domain/models';

export const ProtectedRoute = ({
  children: ProtectedComponent,
  ...otherProps
}: RouteProps) => {
  const loggedIn = useSelector((root: AppStore) => root.auth.loggedIn);
  
  return (
    <Route
      {...otherProps}
      render={({location}: any) =>
        loggedIn === true && ProtectedComponent ? (
          ProtectedComponent
        ) : (
          <Redirect
            to={{
              pathname: '/auth',
              state: {
                from: location
              }
            }}
          />
        )
      }
    />
  );
};
