import React from 'react';

import { WidgetProps } from '../widget.model';
import { useContracts, useTasks } from 'domain/swr';

import './TasksByContract.scss';
import { BaseWidget, BaseWidgetTitle } from '../BaseWidget';
import { Column, DataLink, Select } from 'components';
import {
  ActionCellValue,
  ActionIconContainer,
  DefaultCellValue,
  HeaderField,
  List,
  ListElementRenderer,
  ListHeader,
  ListWrapper,
  NoElements,
} from 'pages/shared/list';
import { TaskList } from 'domain/models';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router';

const headers: HeaderField[] = [
  {
    code: 'name',
    label: 'Name',
    col: 4,
    sortable: false,
  },
  {
    code: 'contract',
    label: 'Contract',
    col: 4,
    sortable: false,
  },
  {
    code: 'deliverable',
    label: 'Deliverable',
    col: 4,
    sortable: false,
  },
  {
    code: 'action',
    label: '',
    col: 1,
    sortable: false,
  },
];

export const TasksByContractWidget = (props: WidgetProps) => {
  const history = useHistory();

  const [contracts] = useContracts({ page: 0, size: 999 }, {});

  const [selectedContract, setSelectedContract] = React.useState(
    contracts.content[0]
  );

  const [tasksByContract] = useTasks(
    { page: 0, size: 10 },
    { contract: selectedContract?.id }
  );

  const getContract = (id: number) => {
    return contracts.content.find((c) => c.id === id)!;
  };

  const selectOptions = React.useMemo(() => {
    return contracts.content.map((c) => ({ label: c.name, value: c.id }));
  }, [contracts]);

  const columnRenderer: ListElementRenderer = (
    field: HeaderField,
    element: TaskList
  ) => {
    switch (field.code) {
      case 'deliverable':
        return (
          <DefaultCellValue title={element.deliverable.name}>
            <DataLink
              relPath={`/app/contract/${element.deliverable.contract}/deliverable/${element.deliverable.id}`}
            >
              {element.deliverable.name}
            </DataLink>
          </DefaultCellValue>
        );
      case 'contract':
        return (
          <DefaultCellValue title={selectedContract.name}>
            <DataLink relPath={`/app/contract/${selectedContract.id}`}>
              {selectedContract.name}
            </DataLink>
          </DefaultCellValue>
        );
      case 'action':
        return (
          <ActionCellValue>
            <ActionIconContainer
              onClick={() => history.push(`/app/task/${element.id}`)}
            >
              <FontAwesomeIcon
                icon={faArrowRight}
                className='action-icon'
              ></FontAwesomeIcon>
            </ActionIconContainer>
          </ActionCellValue>
        );
      default:
        return null;
    }
  };
  
  return (
    <BaseWidget {...props}>
    <BaseWidgetTitle content='Tasks by contract' />
      <Column className='task-by-contract-container'>
        <Select
          className='contract-select'
          options={selectOptions}
          value={{
            label: selectedContract?.name,
            value: selectedContract?.id,
          }}
          onChange={({ value }: { value: number }) =>
            setSelectedContract(getContract(value))
          }
        />
      </Column>
      {(selectedContract !== undefined) ? (<>
        <ListWrapper>
          <NoElements>
            There are no tasks for contract {selectedContract.name}
          </NoElements>

          <ListHeader fields={headers} onSortChange={() => {}} />
          <List
            className='widget-list'
            fields={headers}
            decorator
            data={tasksByContract.content}
            columnRenderer={columnRenderer}
            />
        </ListWrapper>
      </>) : 
        (<div className='no-contracts'>There are no contracts</div>)}
    </BaseWidget>
  );
};
