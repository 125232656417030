export enum Family {
  ATMOSPHERE,
  LAND,
  OCEAN
}

export interface ECV {
  id: number;
  family: Family;
  group: string;
  name: string;
  description: string;
  products: string[];
}