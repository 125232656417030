import { ConfirmFormData, ErrorResponse, Token } from 'domain/models';
import { Api } from '../core';

export class AuthorizationService {

  static getAuthorization(): Token {
    try {
      const auth = localStorage.getItem('auth:token');
      return JSON.parse(auth || '') as Token;
    } catch (err) {
      throw new Error(
        'AuthorizationService :: Unable to retrieve authorization!'
      );
    }
  }

  static saveAuthorization(token: Token) {
    localStorage.setItem('auth:token', JSON.stringify(token));
  }

  static removeAuthorization() {
    localStorage.removeItem('auth:token');
  }

  public login({
    password,
    username
  }: {
    password: string;
    username: string;
  }): Promise<Token | ErrorResponse> {
    return fetch(Api.baseUrl + Api.auth.login, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        username: username,
        password
      })
    })
      .then((res: Response) => res.json())
      .then((token: Token) => {
        AuthorizationService.saveAuthorization(token);
        return token;
      });
  }

  public confirm(confirmData: ConfirmFormData): Promise<Response> {
    return fetch(Api.baseUrl + Api.auth.confirm, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(confirmData)
    });
  }

  public lostPassword({ mail }: { mail: string; }): Promise<Response> {
    return fetch(Api.baseUrl + Api.auth.lostPassword, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: mail })
    });
  }

  public renew(): Promise<Token> {
    const token = AuthorizationService.getAuthorization();
    return fetch(Api.baseUrl + Api.auth.renew, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.token}`
      }
    })
      .then((res: Response) => {
        if (res.status === 200) {
          return res.json();
        }
        throw new Error(res.statusText);
      })
      .then((token: Token) => {
        AuthorizationService.saveAuthorization(token);
        return token;
      });
  }
}
