import React from 'react';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import { Row } from 'components';

import './HelpLink.scss';
import ReactTooltip from 'react-tooltip';

interface HelpLinkProps {
  href: string;
}
export const HelpLink = (props: HelpLinkProps) => {
  const { t } = useTranslation('common');

  return (
    <Row className='help-link'>
      <ReactTooltip
        id='help-link-tooltip'
        className='tooltip'
        effect='solid'
        place='bottom'
      >
        Go to documentation
      </ReactTooltip>
      <Link
        to={props.href}
        target='_blank'
        data-for='help-link-tooltip'
        data-tip
      >
        {t('common:components.HelpLink.linkText')}
        <FontAwesomeIcon icon={faQuestionCircle} className='help-link-icon' />
      </Link>
    </Row>
  );
};
