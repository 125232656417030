import { createStore, combineReducers } from 'redux';

import { AppStore } from 'domain/models';

import { authReducer } from './auth';
import { rootReducer } from './root';

export const appReducer = combineReducers<AppStore>({
  auth: authReducer,
  root: rootReducer
});

export default createStore(appReducer);
