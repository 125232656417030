import React from 'react';

import { useTranslation } from 'react-i18next';

import { Input, Form, LoadingButton, Button } from 'components';

import { ConfirmFormData } from 'domain/models';

import './ConfirmForm.scss';
import { useHistory } from 'react-router';

export interface ConfirmFormProps {
  username: string;
  isRecovery?: boolean;
  loading: boolean;
  onSubmit(formData: ConfirmFormData): void;
}

export const ConfirmForm = ({ username, isRecovery, loading, onSubmit }: ConfirmFormProps) => {
  const { t } = useTranslation(['auth', 'common']);

  const history = useHistory();
  
  const [ passwordRepeat, setPasswordRepeat ] = React.useState('');

  const [formData, setFormData] = React.useState<ConfirmFormData>({
    username: username,
    confirmationToken: '',
    password: '',
  });

  const intOnSubmit = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    evt.preventDefault();
    evt.stopPropagation();
    if (!submitDisabled()) {
      onSubmit(formData);
    }
  };

  const submitDisabled = React.useCallback((): boolean => {
    const { username, password } = formData;
    if (!username || !password) {
      return true;
    }

    if (password.length < 8) {
      return true;
    }

    if (password !== passwordRepeat) {
      return true;
    }

    if (loading) {
      return true;
    }

    return false;
  }, [loading, formData, passwordRepeat]);

  return (
      <Form className='confirmation-form-container'>
        <label>
          {t('auth:loginForm.username.label')}
          <Input
            disabled
            name='username'
            value={formData.username}
          />
        </label>
        <label>
          {t('auth:loginForm.password.label')}
          <Input
            name='password'
            type='password'
            value={formData.password}
            onChange={({ target }) =>
              setFormData({ ...formData, password: target.value })
            }
          />
        </label>
        <label>
          {t('auth:loginForm.passwordRepeat.label')}
          <Input
            name='password-repeat'
            type='password'
            value={passwordRepeat}
            onChange={({ target }) =>
              setPasswordRepeat(target.value)
            }
          />
        </label>
      <LoadingButton
        className='submit-button secondary'
        loading={loading}
        onClick={intOnSubmit}
        disabled={submitDisabled()}
        type='submit'
      >
        {isRecovery ? t('common:actions.confirm') : t('common:actions.verify')}
      </LoadingButton>
      <Button
        className='submit-button'
        onClick={() => history.push('/')}
        type='submit'
      >
        {t('common:actions.back')}
      </Button>
      </Form>
  );
};
