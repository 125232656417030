import { AppStore } from 'domain/models';
import React from 'react';
import { useSelector } from 'react-redux';
import { BaseWidget, BaseWidgetTitle } from '../BaseWidget';
import { WidgetProps } from '../widget.model';
import { useKpiMetrics } from './useKpiMetrics';

import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Column, Row, Select } from 'components';

import './KpiMetrics.scss';

type Magnitude = 'absolute' | 'percentage';

const getDaysOptions = (t: TFunction) => [
  {
    label: t('widgets:deliverablesKpis.days.lastMonth'),
    value: 30,
  },
  {
    label: t('widgets:deliverablesKpis.days.last3Months'),
    value: 90,
  },
  {
    label: t('widgets:deliverablesKpis.days.last6Months'),
    value: 180,
  },
  {
    label: t('widgets:deliverablesKpis.days.lastYear'),
    value: 360,
  },
];

export const KpiMetricsWidget = (props: WidgetProps) => {
  const { t } = useTranslation('widgets');

  const daysOptions = React.useMemo(() => getDaysOptions(t), [t]);

  const rootContract = useSelector(
    (store: AppStore) => store.root.rootContract
  );

  const [days, setDays] = React.useState(daysOptions[0].value);
  const [magnitude, setMagnitude] = React.useState<Magnitude>('percentage');

  const [kpiMetrics] = useKpiMetrics({ contract_id: rootContract.id, days });

  const fixedMetrics = React.useMemo(() => {
    if (kpiMetrics.total === 0) {
      return {
        total: 0,
        approved: 0,
        outstanding: 0,
        late: 0,
        delivered: 0,
        rejected: 0,
      };
    }
    if (magnitude === 'absolute') {
      return kpiMetrics;
    } else {
      return {
        total: kpiMetrics.total,
        approved: (kpiMetrics.approved / kpiMetrics.total) * 100,
        outstanding: (kpiMetrics.outstanding / kpiMetrics.total) * 100,
        late: (kpiMetrics.late / kpiMetrics.total) * 100,
        delivered: (kpiMetrics.delivered / kpiMetrics.total) * 100,
        rejected: (kpiMetrics.rejected / kpiMetrics.total) * 100,
      };
    }
  }, [magnitude, kpiMetrics]);

  return (
    <BaseWidget {...props}>
      <BaseWidgetTitle content={props.code} />
      <Row className='kpi-metrics-config'>
        <KpiMetricsMagnitudeSelector
          selectedMagnitude={magnitude}
          magnitudeSelected={setMagnitude}
        />
        <span className='kpi-total-count'>
          {t('widgets:deliverablesKpis.kpis.total')}: {kpiMetrics.total}
        </span>
        <Select
          options={daysOptions}
          value={daysOptions.find((d) => d.value === days)}
          onChange={({ value }: any) => setDays(value)}
        />
      </Row>
      <Row className='kpi-metrics-container'>
        <KpiMetricEntry
          name={t('widgets:deliverablesKpis.kpis.outstanding')}
          magnitude={magnitude}
          value={fixedMetrics.outstanding}
          color='#666'
        />
        <KpiMetricEntry
          name={t('widgets:deliverablesKpis.kpis.late')}
          magnitude={magnitude}
          value={fixedMetrics.late}
          color='#c76363'
        />
        <KpiMetricEntry
          name={t('widgets:deliverablesKpis.kpis.delivered')}
          magnitude={magnitude}
          value={fixedMetrics.delivered}
          color='#8ec763'
        />
        <KpiMetricEntry
          name={t('widgets:deliverablesKpis.kpis.approved')}
          magnitude={magnitude}
          value={fixedMetrics.approved}
          color='#8ec763'
        />
        <KpiMetricEntry
          name={t('widgets:deliverablesKpis.kpis.rejected')}
          magnitude={magnitude}
          value={fixedMetrics.rejected}
          color='#c76363'
        />
      </Row>
    </BaseWidget>
  );
};

interface KpiMetricsMagnitudeSelectorProps {
  selectedMagnitude: Magnitude;
  magnitudeSelected(mag: Magnitude): void;
}
const KpiMetricsMagnitudeSelector = (
  props: KpiMetricsMagnitudeSelectorProps
) => {
  const { t } = useTranslation('widgets');

  return (
    <Row className='kpi-magnitude-selector'>
      <Row
        className={`mag-option ${
          props.selectedMagnitude === 'absolute' ? 'selected' : ''
        }`}
        onClick={() => props.magnitudeSelected('absolute')}
      >
        <span className='mag-label'>
          {t('widgets:deliverablesKpis.magnitudes.absolute')}
        </span>
      </Row>
      <Row
        className={`mag-option ${
          props.selectedMagnitude === 'percentage' ? 'selected' : ''
        }`}
        onClick={() => props.magnitudeSelected('percentage')}
      >
        <span className='mag-label'>
          {t('widgets:deliverablesKpis.magnitudes.percentage')}
        </span>
      </Row>
    </Row>
  );
};

interface KpiMetricEntryProps {
  name: string;
  value: number;
  magnitude: Magnitude;
  color: string;
}
const KpiMetricEntry = (props: KpiMetricEntryProps) => {
  return (
    <Column className='kpi-metric-entry'>
      <span className='kpi-metric-label'>{props.name}</span>
      <span className='kpi-metric-value' style={{ color: props.color }}>
        {props.value?.toFixed()}
        {props.magnitude === 'percentage' ? '%' : ''}
      </span>
    </Column>
  );
};
