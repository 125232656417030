import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleNotch,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';

import './Button.scss';

type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export const Button = React.forwardRef((props: ButtonProps, ref: any) => {
  const { className, ...otherProps } = props;

  return (
    <button {...otherProps} ref={ref} className={`button ${className || ''}`}>
      {props.children}
    </button>
  );
});

export const LoadingButton = React.forwardRef(
  (props: ButtonProps & { loading?: boolean }, ref: any) => {
    const { className, loading, disabled = false, ...otherProps } = props;

    return (
      <Button
        disabled={disabled || loading}
        ref={ref}
        className={`loading-button ${className || ''}`}
        {...otherProps}
      >
        {props.children}
        {props.loading && (
          <FontAwesomeIcon
            className='button-loading-indicator'
            icon={faCircleNotch}
          />
        )}
      </Button>
    );
  }
);

export const ButtonWithIcon = React.forwardRef(
  (
    props: ButtonProps & {
      icon: IconDefinition;
      iconClassName?: string;
      iconSide?: 'left' | 'right';
    },
    ref: any
  ) => {
    const {
      className,
      iconClassName = '',
      icon,
      iconSide = 'right',
      ...otherProps
    } = props;

    return (
      <Button
        ref={ref}
        className={`button-w-icon icon-${iconSide} ${className || ''}`}
        {...otherProps}
      >
        {iconSide === 'right' ? (
          <>
            {props.children}
            <FontAwesomeIcon
              icon={icon}
              className={`button-w-icon__icon ${iconClassName}`}
            />
          </>
        ) : (
          <>
            <FontAwesomeIcon
              icon={icon}
              className={`button-w-icon__icon ${iconClassName}`}
            />
            {props.children}
          </>
        )}
      </Button>
    );
  }
);
