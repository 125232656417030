import React from 'react';

import { Column, FullPage, FullPageBackdrop, H, Icon, Row } from 'components';

import C3SLogo from 'assets/imgs/C3S.png';

import './RootLoader.scss';

export const RootLoader = () => {
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    let timeout: any;
    // Prevenir que aparezca y desaparezca demasiado rápido y haga un efecto feo
    // Lo mostramos si la carga esta durando mas de 300ms. Lo mismo no se ve jamás :/
    timeout = setTimeout(() => {
      setShow(true);
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return show ? (
    <FullPage className='root-loader-container'>
      <Column className='root-loader'>
        <H.H1>Loading COP-077 application</H.H1>
        <Row className='icons-row'>
          <Icon.CopernicusLogo className='copernicus-logo' width={250} />
          <img className='c3s-logo' width={250} height='auto' alt="C3S Logo" src={C3SLogo} />
        </Row>
      </Column>
    </FullPage>
  ) : null;
};

export const RootSpinner = () => {
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    let timeout: any;
    // Prevenir que aparezca y desaparezca demasiado rápido y haga un efecto feo
    // Lo mostramos si la carga esta durando mas de 300ms. Lo mismo no se ve jamás :/
    timeout = setTimeout(() => {
      setShow(true);
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return show ? (
    <FullPageBackdrop dismissable={false} className='root-spinner'>
      <div className='sk-chase'>
        <div className='sk-chase-dot'></div>
        <div className='sk-chase-dot'></div>
        <div className='sk-chase-dot'></div>
        <div className='sk-chase-dot'></div>
        <div className='sk-chase-dot'></div>
        <div className='sk-chase-dot'></div>
      </div>
    </FullPageBackdrop>
  ) : null;
};
