import { DefaultWeight, Role, Roles, Token } from 'domain/models';

import { differenceInMilliseconds } from 'date-fns';

import * as AuthUtils from '.';

export const isValid = (token: Token): [boolean, number] => {
  const date = new Date(token.validity);

  const delta = differenceInMilliseconds(date, Date.now());

  return [delta > 0, delta];
};

export const getRole = (role: Role) => {
  return Roles.find((r) => r.value == role) || { weight: DefaultWeight };
};

export const isEqualOrHigherTierByKey = (
  userRoleKey: Role[] | Role,
  targetRoleKey: Role,
  exact: boolean = false
): boolean => {
  const compareWeight = (intUserRole: Role) => {
    if (exact) {
      return intUserRole === targetRoleKey;
    } else {
      const userRole = AuthUtils.getRole(intUserRole);
      const targetRole = AuthUtils.getRole(targetRoleKey);

      return userRole.weight >= targetRole.weight;
    }
  };

  if (Array.isArray(userRoleKey)) {
    return userRoleKey.some((key) => compareWeight(key));
  } else {
    return compareWeight(userRoleKey);
  }
};
