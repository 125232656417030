import { TFunction } from 'i18next';
import { TaskList } from '.';
import { ECV } from './ecv.model';
import { Organization } from './organization.model';
import { ContractManager, ContractOfficer, User } from './user.model';

export type Cardinality = string;

export enum DeliverableTemplateFrequency {
  P1Y = 'P1Y',
  P3M = 'P3M',
  P6M = 'P6M',
}

export interface ContractTemplate {
  id?: number;
  name: string;
  disabled: boolean;
  type: ContractType;
}

export interface DeliverableTemplate {
  id?: number;
  identifier: string;
  name: string;
  type: any;
  frequency?: DeliverableTemplateFrequency;
  cardinality?: Cardinality;
  workflow: string;
}

export enum ContractType {
  ECV_DELIVERY = 'ECV_DELIVERY',
  EQC = 'EQC',
}

export enum DeliverableType {
  DOCUMENTATION = 'DOCUMENTATION',
  REPORT = 'REPORT',
  DOCUMENT = 'DOCUMENT',
  MILESTONE = 'MILESTONE',
  OTHER = 'OTHER',
  DATASET = 'DATASET',
  CDR = 'CDR',
  ICDR = 'TCDR',
}

export type DeliverableState = 'PENDING' | 'REJECTED' | 'FINISHED' | 'STARTED';

export interface Deliverable {
  rootId: number;
  id: number;
  contract: number;
  name: string;
  template?: number;
  lineage: string;
  lineageId: number;
  type: DeliverableType;
  start: string;
  endDate: string;
  state: DeliverableState;
  deadline: string;
  document: any;
  workflow: string;
  processId?: string;
  ecvs: ECV[];
  contentType: any;
  currentTasks?: TaskList[];
}

export interface Contract {
  id: number;
  template?: ContractTemplate;
  deliverables: Deliverable[];
  contractOfficers: ContractManager[];
  technicalOfficers: ContractOfficer[];
  contractor: Organization;
  reviewer: Organization;
  startDate: Date;
  endDate: Date;
  ecvs: ECV[];
  name: string;
}

export interface ListContract extends Pick<Contract, 'id' | 'name'> {
  template?: number;
  contractOfficers: number[];
  technicalOfficers: number[];
  contractor: number;
  reviewer: number;
  startDate: string;
  endDate: string;
  ecvs: number[];
  deliverables: any;
}

export type RootContract = Pick<Contract, 'name'> & { id?: number };

export interface CreateContractDraftDeliverablesConfig {
  name: string;
  template: number;
  period: DeliverableTemplateFrequency;
  type: DeliverableType;
  cardinality: Cardinality;
  workflow: string;
}
export interface CreateContractDraft {
  name: string;
  template: number;
  contractOfficers: number[];
  technicalOfficers: number[];
  contractor: number;
  reviewer: number;
  startDate: string;
  endDate: string;
  ecvs: number[];
  deliverablesConfiguration: CreateContractDraftDeliverablesConfig[];
}

export interface CreateContractDraftResponse
  extends Omit<CreateContractDraft, 'deliverablesConfiguration'> {
  id: null;
  deliverables: Deliverable[];
}

export interface HistoryTask {
  id: string;
  name: string;
  taskDefinitionKey: string;
  assigned?: User;
  deliverable: Deliverable;
  startTime: string;
  endTime?: string;
}

export const getStates = (t: TFunction) => {
  const states: DeliverableState[] = [
    'FINISHED',
    'PENDING',
    'REJECTED',
    'STARTED',
  ];
  return states.map((s) => ({
    label: t(`deliverable:states.${s}`, { defaultValue: s }),
    value: s,
  }));
};

export const AllContractsOption: RootContract = {
  id: undefined,
  name: 'common:components.sidemenu.contractsSelector.all.label',
};
