import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationCircle,
  faArrowRight
} from '@fortawesome/free-solid-svg-icons';
import { CSSTransition } from 'react-transition-group';

import './Input.scss';

export type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export const Input = React.forwardRef((props: InputProps, ref?: any) => {
  const { className, ...otherProps } = props;

  return (
    <input className={`input ${className || ''}`} ref={ref} {...otherProps} />
  );
});

export const InputWithErrors = React.forwardRef(
  (props: InputProps & { error?: string }, ref: any) => {
    const { error, className, ...otherProps } = props;

    return (
      <div className={`input-w-error ${error ? 'has-error': ''}`}>
        <Input {...otherProps} ref={ref} />
        <CSSTransition
          timeout={200}
          unmountOnExit
          in={!!error}
          classNames='input-error-transition'
        >
          <span className='error'>
            <FontAwesomeIcon
              className='error-icon'
              icon={faExclamationCircle}
            />
            {error}
          </span>
        </CSSTransition>
      </div>
    );
  }
);

export const InputWithButton = React.forwardRef(
  (
    props: InputProps & {
      onButtonClick(evt: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
    },
    ref: any
  ) => {
    const { className, onButtonClick, ...otherProps } = props;

    return (
      <div className='input-w-button'>
        <Input {...otherProps} className='input-w-button-input' ref={ref} />
        <button onClick={onButtonClick} className='input-w-button-button'>
          <FontAwesomeIcon
            icon={faArrowRight}
            className='input-w-button-icon'
          />
        </button>
      </div>
    );
  }
);

export const Checkbox = React.forwardRef(
  (props: Omit<InputProps, 'type'>, ref: any) => {
    const { className, ...otherProps } = props;

    return (
        <input
          type='checkbox'
          className={`checkbox ${className || ''}`}
          ref={ref}
          {...otherProps}
        />
    );
  }
);
