import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

import { Row } from './Flex';

import { range } from 'domain/utils';

import { Page, PageInfo } from 'domain/models';

import './Pagination.scss';
import { useTranslation } from 'react-i18next';
import { Paragraph } from './Paragraph';
import { Select } from './Select';

const toRenderPages = (
  page: number,
  pages: number
): { toRender: number[]; prevEllipsis: boolean; nextEllipsis: boolean } => {
  let toRender, prevEllipsis, nextEllipsis;
  if (pages > 3) {
    if (page === 1) {
      toRender = range(3, 1);
      prevEllipsis = false;
      if (page + 3 < pages) {
        nextEllipsis = true;
      } else {
        nextEllipsis = false;
      }
    } else if (page === pages) {
      toRender = [pages - 2, pages - 1, pages];
      nextEllipsis = false;
      if (pages - 3 > 1) {
        prevEllipsis = true;
      } else {
        prevEllipsis = false;
      }
    } else {
      toRender = [page - 1, page, page + 1];
      prevEllipsis = true;
      nextEllipsis = true;
      if (page - 2 <= 1) {
        prevEllipsis = false;
      }

      if (page + 2 >= pages) {
        nextEllipsis = false;
      }
    }
  } else {
    toRender = range(pages, 1);
    prevEllipsis = false;
    nextEllipsis = false;
  }

  toRender = toRender.filter((n) => n !== 1 && n !== pages);
  return { toRender, prevEllipsis: prevEllipsis, nextEllipsis: nextEllipsis };
};

export type PaginationProps = Pick<Page<{}>, 'page' | 'totalPages'> & {
  size?: number;
  totalElements?: number;
  empty?: boolean;
  onPageChange(page: number): void;
  onPageSizeChange?(pageSize: number): void;
};

export const Pagination = (props: PaginationProps) => {
  const { page, totalPages, onPageChange } = props;

  const number = page + 1;
  const { toRender, prevEllipsis, nextEllipsis } = toRenderPages(
    number,
    totalPages
  );

  const intOnPageChange = React.useCallback(
    (evtPage: 'prev' | 'next' | number) => {
      if (evtPage === number) {
        return;
      }

      if (evtPage === 'prev') {
        if (page > 0) {
          onPageChange(page - 1);
        }
      } else if (evtPage === 'next') {
        if (page < totalPages - 1) {
          onPageChange(page + 1);
        }
      } else {
        onPageChange(evtPage - 1);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [number, totalPages, onPageChange]
  );

  if (props.empty === true) {
    return null;
  }

  return (
    <Row className='pagination-container'>
      <Row className='pagination'>
        <PrevButton onClick={() => intOnPageChange('prev')}></PrevButton>
        <PaginationButton
          active={number === 1}
          onClick={() => intOnPageChange(1)}
        >
          1
        </PaginationButton>
        {prevEllipsis && (
          <PaginationButton className='ellipsis' onClick={() => {}}>
            ...
          </PaginationButton>
        )}
        {toRender.map((n) => (
          <PaginationButton
            key={n}
            onClick={() => intOnPageChange(n)}
            active={number === n}
          >
            {n}
          </PaginationButton>
        ))}
        {nextEllipsis && (
          <PaginationButton className='ellipsis' onClick={() => {}}>
            ...
          </PaginationButton>
        )}
        {totalPages > 1 && (
          <PaginationButton
            active={number === totalPages}
            onClick={() => intOnPageChange(totalPages)}
          >
            {totalPages}
          </PaginationButton>
        )}
        <NextButton onClick={() => intOnPageChange('next')}></NextButton>
      </Row>
      {props.size && props.totalElements && props.onPageSizeChange && (
        <PaginationInfo
          onPageSizeChange={props.onPageSizeChange}
          pageSize={props.size}
          totalElements={props.totalElements}
        />
      )}
    </Row>
  );
};

type PaginationButtonProps = React.PropsWithChildren<{
  className?: string;
  active?: boolean;
  onClick(evt: any): void;
}>;
const PaginationButton = ({
  className = '',
  active,
  onClick,
  children,
}: PaginationButtonProps) => (
  <Row
    className={`pagination-button ${active ? 'active' : ''} ${className}`}
    onClick={onClick}
  >
    {children}
  </Row>
);

const PrevButton = (props: PaginationButtonProps) => (
  <PaginationButton {...props}>
    <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon>
  </PaginationButton>
);

const NextButton = (props: PaginationButtonProps) => (
  <PaginationButton {...props}>
    <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
  </PaginationButton>
);

const PageSizeOptions = [10, 25, 50];
interface PaginationInfoProps {
  totalElements: number;
  pageSize: number;
  onPageSizeChange(pageSize: number): void;
}
const PaginationInfo = (props: PaginationInfoProps) => {
  const { t } = useTranslation('common');

  return (
    <Row className='pagination-info-row'>
      Showing{' '}
      <select
        className='pagination-info-select'
        value={props.pageSize}
        onChange={({ target }) => props.onPageSizeChange(Number(target.value))}
      >
        {PageSizeOptions.map((p) => (
          <option key={p}>{p}</option>
        ))}
      </select>
      elements of {props.totalElements}
    </Row>
  );
};
