import React from 'react';

import { Row, Column, Paragraph } from 'components';

import { ECV } from 'domain/models';

import './EcvItem.scss';

export type SelectableEcv = ECV & { selected?: boolean };

interface EcvItemProps {
  ecv: ECV;
  selected?: boolean;
  onClick?(ecv: ECV): void;
}
export const EcvItem = (props: EcvItemProps) => {
  return (
    <Row
      className={`ecv ${props.selected ? 'selected' : ''} ${
        props.onClick ? 'selectable' : ''
      }`}
      onClick={() => props.onClick && props.onClick(props.ecv)}
    >
      <Column className='ecv-left-side'>
        <span className='ecv-name'>{props.ecv.name}</span>
        <span className='ecv-family-group'>
          {props.ecv.family} ({props.ecv.group})
        </span>
        <Paragraph className='ecv-description'>
          {props.ecv.description}
        </Paragraph>
      </Column>
      <Column className='ecv-right-side'>
        <span className='ecv-products-title'>Products</span>
        <ul className='ecv-products-list'>
          {props.ecv.products.map((p, i) => (
            <li className='ecv-product' key={i}>
              {p}
            </li>
          ))}
        </ul>
      </Column>
    </Row>
  );
};
