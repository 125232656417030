import React from 'react';

import ReactSelect, { Props, components } from 'react-select';

import './Select.scss';

export const Select = React.forwardRef<any, Props>(
  ({ className = '', ...props }: Props, ref: any) => {
    // Same as default option but with support for title. Label by default
    const Option = (props: any) => {
      return (
        <div style={props.styles} title={props.title ?? props.label}>
          <components.Option {...props}/>
        </div>
      );
    };

    return (
      <ReactSelect
        ref={ref}
        className={`select ${className}`}
        classNamePrefix='cop-select'
        components={{ Option }}
        {...props}
      />
    );
  }
);
