import React from 'react';

type DivProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const Horizontal = React.forwardRef(
  (props: DivProps & { height: string }, ref: any) => {
    const { height, style, ...otherProps } = props;
    return (
      <div
        style={{height, width: '100%', ...style }}
        {...otherProps}
        ref={ref}
      ></div>
    );
  }
);

const Vertical = React.forwardRef(
  (props: DivProps & { width: string }, ref: any) => {
    const { width, style, ...otherProps } = props;
    return (
      <div
        style={{ width, height: '100%', ...style }}
        {...otherProps}
        ref={ref}
      ></div>
    );
  }
);

export const Divider = {
  Horizontal,
  Vertical
};
