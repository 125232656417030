import React, { Suspense } from 'react';

import { SWRConfig } from 'swr';
import { useDispatch } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';

import { ProtectedRoute } from 'components';

import { RootLoader } from './app/root/RootLoader';

import { SWRBaseFetcher } from 'domain/swr/BaseFetcher';
import { AuthorizationService } from 'domain/services';
import { isValid } from 'domain/utils';
import { loginSuccess, setProfile } from 'domain/store/auth';
import { getProfile } from 'domain/swr';

const RootApp = React.lazy(
  () => import(/* webpackChunkName: "app.root" */ 'pages/app/root/RootApp')
);

const AuthPage = React.lazy(
  () => import(/* webpackChunkName: "auth" */ 'pages/app/auth/AuthPage')
);

const ConfirmPage = React.lazy(
  () => import(/* webpackChunkName: "auth.confirm" */ 'pages/app/auth/ConfirmPage')
);

const LostPasswordPage = React.lazy(
  () => import(/* webpackChunkName: "auth.lost-password" */ 'pages/app/auth/LostPasswordPage')
);

export const Router = React.memo(() => {
  const fetcher = React.useRef(new SWRBaseFetcher());

  const dispatch = useDispatch();

  const [authCheck, setAuthCheck] = React.useState(false);

  React.useEffect(() => {
    try {
      const token = AuthorizationService.getAuthorization();
      if (token) {
        const [valid] = isValid(token);

        if (valid) {
          dispatch(loginSuccess(token));
        }
      }
    } catch (err) {}


    setAuthCheck(true);

    getProfile().then(currentUser => {
      dispatch(setProfile(currentUser));
    }).catch(err => {})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return authCheck ? (
    <Suspense fallback={<RootLoader />}>
      <Switch>
        <Route path='/auth'>
          <AuthPage />
        </Route>
        <Route path='/user/confirm'>
          <ConfirmPage />
        </Route>
        <Route path='/user/recovery'>
          <ConfirmPage isRecovery />
        </Route>
        <Route path='/user/lost-password'>
          <LostPasswordPage />
        </Route>
        <ProtectedRoute path='/app'>
          <SWRConfig
            value={{
              fetcher: (resource, init) =>
                fetcher.current
                  .make(resource, init)
                  .then((data) => {
                    if ('_embedded' in data) {
                      const embKey = Object.keys(data._embedded)[0];
                      if (embKey) {
                        return data._embedded[embKey];
                      }
                    }
                    return data;
                  }),
              suspense: true,
            }}
          >
            <RootApp />
          </SWRConfig>
        </ProtectedRoute>
        <Redirect to='/app'></Redirect>
      </Switch>
    </Suspense>
  ) : null;
});
