import useSwr from 'swr';

import { Api } from 'domain/core';
import {
  Task,
  TaskFilter,
  TaskList,
  TaskSchema,
} from 'domain/models/task.model';
import { toQueryParams } from 'domain/utils';
import { BaseFetcher, SWRBaseFetcher } from './BaseFetcher';
import { Page, PageQuery, ResponseAsArray } from 'domain/models';

export const urlGenerator = (base: string): any => {
  return (
    pageQuery: PageQuery = {
      page: 0,
      size: 10,
    },
    filter: TaskFilter
  ) => {
    filter = Object.fromEntries(
      Object.entries(filter).filter(([_, val]) => val != null)
    );
    const queryString = toQueryParams({ ...filter, ...pageQuery });
    return queryString ? `${base}?${queryString}` : `${base}`;
  };
};

export const tasksHistoryUrl = urlGenerator(`${Api.baseUrl}/tasks/history`);
export const tasksUrl = urlGenerator(`${Api.baseUrl}/tasks`);

export const useTasks = (
  pageQuery: PageQuery = {
    page: 0,
    size: 10,
  },
  filter: TaskFilter
): ResponseAsArray<Page<TaskList>> => {
  const url = tasksUrl(pageQuery, filter);

  const { data, isValidating, error, mutate, revalidate } =
    useSwr<Page<TaskList>>(url);

  return [data as Page<TaskList>, isValidating, error, mutate, revalidate];
};

export const useHistoricalTasks = (
  pageQuery: PageQuery = {
    page: 0,
    size: 10,
  },
  filter: TaskFilter
): ResponseAsArray<Page<TaskList>> => {
  const url = tasksHistoryUrl(pageQuery, filter);

  const { data, isValidating, error, mutate, revalidate } =
    useSwr<Page<TaskList>>(url);

  return [data as Page<TaskList>, isValidating, error, mutate, revalidate];
};

export const downloadTasksXLS = (
  pageQuery: PageQuery = {
    page: 0,
    size: 10,
  },
  filter: TaskFilter
): Promise<any> => {
  const url = tasksUrl(pageQuery, filter);
  return new BaseFetcher().make(url, {
    method: 'GET',
    headers: {
      accept: 'application/vnd.ms-excel',
    },
  });
};

export const useTask = (id: string): ResponseAsArray<Task> => {
  const { data, isValidating, error, mutate, revalidate } = useSwr<Task>(
    // `/data/contract.mock.json`
    `${Api.baseUrl}/tasks/${id}`
  );

  return [data as Task, isValidating, error, mutate, revalidate];
};

export const useTaskSchema = (id: string): ResponseAsArray<TaskSchema> => {
  const { data, isValidating, error, mutate, revalidate } = useSwr<TaskSchema>(
    `${Api.baseUrl}/tasks/${id}/schema`
  );
  return [data as TaskSchema, isValidating, error, mutate, revalidate];
};

export const useTaskValue = (id: string): ResponseAsArray<any> => {
  const { data, isValidating, error, mutate, revalidate } = useSwr<any>(
    `${Api.baseUrl}/tasks/${id}/values`
  );
  return [data, isValidating, error, mutate, revalidate];
};

export const useUpdateTask = (task: Task): ResponseAsArray<null> => {
  const url = `${Api.baseUrl}/tasks`;
  const { data, isValidating, error, mutate } = useSwr(url);

  mutate(async (tasks: Task[]) => {
    const resource = `${url}/${task.id}`;
    await new SWRBaseFetcher().make(resource, {
      method: 'PATCH',
      body: JSON.stringify(task),
    });
    const nonUpdated = tasks.filter((t) => t.id !== task.id);
    return [...nonUpdated, task];
  });

  return [data as any, isValidating, error];
};

export const closeTask = (id: string, payload: any): Promise<any> => {
  return new SWRBaseFetcher().make(`${Api.baseUrl}/tasks/${id}`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
};

export const useCloseTask = (
  id: string,
  payload: any
): ResponseAsArray<null> => {
  const url = `${Api.baseUrl}/tasks`;
  const { data, isValidating, error, mutate } = useSwr(url);

  mutate(async (tasks: Task[]) => {
    const resource = `${url}/${id}`;
    await new SWRBaseFetcher().make(resource, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
  }, true);

  return [data as any, isValidating, error];
};
