import useSwr from 'swr'

import { Organization, OrganizationBase, ResponseAsArray, User } from 'domain/models'
import { Api } from 'domain/core'
import { BaseFetcher } from './BaseFetcher'

export const useOrganizations = (): ResponseAsArray<Organization[]> => {
  const { data, isValidating, error, revalidate } = useSwr(`${Api.baseUrl}/organizations`)

  return [
    data?.content,
    isValidating,
    error,
    /* undefined */,
    revalidate
  ]
}

export const useOrganizationsUsers = (organizationId?: number): ResponseAsArray<User[]> => {

  const { data, isValidating, error } = useSwr(
    
    (organizationId) ? `${Api.baseUrl}/rest/organizations/${organizationId}/users` : null)

  return [
    data,
    isValidating,
    error
  ]
}

export const createOrganization = (organization: OrganizationBase): Promise<any> => {
  return new BaseFetcher().make(`${Api.baseUrl}/rest/organizations`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(organization),
  });
}

export const updateOrganization = (organization: Partial<Organization>): Promise<any> => {
  return new BaseFetcher().make(`${Api.baseUrl}/rest/organizations/${organization.id!}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(organization),
  });
}

export const deleteOrganization = (organizationId: number): Promise<any> => {
  return new BaseFetcher().make(`${Api.baseUrl}/rest/organizations/${organizationId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  });
}
