import React from 'react';

import { Column, GridItem, H, Row } from 'components';

import './BaseWidget.scss';
import Skeleton from 'react-loading-skeleton';

export interface BaseWidgetProps {
  code: string;
  className?: string;
  width: number;
}
export const BaseWidget = (props: React.PropsWithChildren<BaseWidgetProps>) => {
  return (
    <GridItem
      className={`base-widget ${props.className ?? ''} widget-${props.code}`}
      col={props.width}
    >
      <Column className='base-widget-content'>{props.children}</Column>
    </GridItem>
  );
};

interface WidgetTitleProps {
  content?: string | React.ReactNode;
  title?: string;
  className?: string;
}
export const BaseWidgetTitle = (
  props: React.PropsWithChildren<WidgetTitleProps>
) => {
  const Content = () => {
    if (props.content) {
      if ('string' === typeof props.content) {
        return <H.H3 className='widget-heading'>{props.content}</H.H3>;
      } else {
        return props.content as any;
      }
    } else {
      return props.children;
    }
  };

  return (
    <Row className={`base-widget-title ${props.className}`} title={props.title}>
      <Content />
    </Row>
  );
};

export const WidgetSkeleton = (props: BaseWidgetProps) => {
  return (
    <BaseWidget {...props}>
      <Skeleton height={400} width='100%' />
    </BaseWidget>
  );
};

export const WidgetErrorBoundary = (props: BaseWidgetProps) => {
  return <BaseWidget {...props}>
    <H.H4>An error has occurred</H.H4>
  </BaseWidget>;
};
