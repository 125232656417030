import React from 'react';

import { WidgetProps } from '../widget.model';
import { useDeliverables, useECVs } from 'domain/swr';

import './ContractorDeliverableList.scss';
import { BaseWidget, BaseWidgetTitle } from '../BaseWidget';
import { Column, DataLink, Pagination, Row, Select } from 'components';
import {
  AppStore,
  Deliverable,
  DeliverableType,
  PageQuery,
  SortDirection,
} from 'domain/models';
import { useSelector } from 'react-redux';
import {
  ActionCellValue,
  ActionIconContainer,
  DefaultCellValue,
  HeaderField,
  List,
  ListElementRenderer,
  ListHeader,
  ListWrapper,
  NoElements,
} from 'pages/shared';
import { asSelect } from 'domain/utils';
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { DashOnEmpty } from 'pages/shared/DashOnEmpty';

const headers: HeaderField[] = [
  {
    code: 'name',
    label: 'Name',
    col: 4,
    sortable: true,
  },
  {
    code: 'deadline',
    label: 'Deadline',
    col: 2,
    sortable: true,
  },
  {
    code: 'enddate',
    label: 'Delivered Date',
    col: 2,
    sortable: true,
  },
  {
    code: 'task',
    label: 'Pending tasks',
    col: 4,
    sortable: false,
  },
  {
    code: 'type',
    label: 'Type',
    col: 2,
    sortable: false,
  },
  {
    code: 'documents',
    label: 'Documents',
    col: 3,
    sortable: false,
  },
  {
    code: 'action',
    label: '',
    col: 1,
    sortable: false,
  },
];

export const ContractorDeliverableListWidget = (props: WidgetProps) => {
  const selectedContract = useSelector(
    (store: AppStore) => store.root.rootContract
  );

  const [selectedECVs, setSelectedECVs] = React.useState<
    { value: number; label: string }[]
  >([]);
  const [selectedTypes, setSelectedTypes] = React.useState<
    { value: string; label: string }[]
  >([]);
  const [sort, setSort] = React.useState<
    { code: string; direction: SortDirection } | undefined
  >({ code: 'deadline', direction: 'ASC' });

  const deliverableFilter = React.useMemo(() => {
    return {
      contractId: selectedContract?.id,
      ecv: selectedECVs.map((ecv) => ecv.value),
      types: selectedTypes.map((type) => type.value),
      sort: sort ? `${sort.code},${sort.direction.toUpperCase()}` : undefined,
      tasks: true,
    };
  }, [selectedContract, selectedECVs, selectedTypes, sort]);

  const [pageQuery, setPageQuery] = React.useState<PageQuery>({
    page: 0,
    size: 5,
  });

  const [ecvs] = useECVs({ page: 0, size: 999 });
  const [deliverables] = useDeliverables(deliverableFilter, pageQuery);

  const ecvOptions = React.useMemo(() => asSelect<number>(ecvs), [ecvs]);
  const typeOptions = Object.keys(DeliverableType).map((d) => ({
    label: d,
    value: d,
  }));

  const columnRenderer: ListElementRenderer = (
    field: HeaderField,
    element: Deliverable
  ) => {
    switch (field.code) {
      case 'name':
        return (
          <DefaultCellValue title={element.name}>
            <DataLink
              relPath={`/app/contract/${element.contract}/deliverable/${element.id}`}
            >
              {element.name}
            </DataLink>
          </DefaultCellValue>
        );
      case 'task':
        return (
          <DefaultCellValue>
            {element.currentTasks?.length && element.currentTasks.length > 0
              ? element.currentTasks?.map((task) => {
                  return (
                    <DataLink relPath={`/app/task/${task.id}`}>
                      {task.name}
                    </DataLink>
                  );
                })
              : '-'}
          </DefaultCellValue>
        );

      case 'deadline':
        return (
          <DefaultCellValue title={element.deadline}>
            <DashOnEmpty text={element.deadline} />
          </DefaultCellValue>
        );

      case 'enddate':
        return (
          <DefaultCellValue title={element.endDate}>
            <DashOnEmpty text={element.endDate} />
          </DefaultCellValue>
        );

      case 'type':
        return (
          <DefaultCellValue title={element.type}>
            <DashOnEmpty text={element.type} />
          </DefaultCellValue>
        );
      case 'documents':
        const url = `/app/contract/${element.contract}/deliverable/${element.id}`;
        return (
          <DefaultCellValue className='documents-cell'>
            <Link
              className='row'
              to={{ pathname: url, state: { routeActiveTab: 'documents' } }}
            >
              Go to documents
              <FontAwesomeIcon icon={faEye} className='action-icon' />
            </Link>
          </DefaultCellValue>
        );
      default:
        return null;
    }
  };

  return (
    <BaseWidget {...props}>
      <BaseWidgetTitle content='Contractor Deliverables' />
      <Column className='cds-deliverables-container'>
        <Row className='cds-deliverables-selects'>
          <Select
            isMulti
            className='type-select'
            options={typeOptions}
            value={selectedTypes}
            onChange={setSelectedTypes}
          />
          <Select
            isMulti
            className='ecv-select'
            options={ecvOptions}
            value={selectedECVs}
            onChange={setSelectedECVs}
          />
        </Row>

        <Column className='cds-deliverables-wrapper'>
          <ListWrapper>
            <NoElements>
              There are no deliverables that matches current filter
            </NoElements>

            <ListHeader
              fields={headers}
              sortDir={sort?.direction}
              sortField={sort?.code}
              onSortChange={(sort) => {
                setSort(
                  sort.sortField && sort.sortDir
                    ? { code: sort.sortField, direction: sort.sortDir }
                    : undefined
                );
              }}
            />
            <List
              decorator={false}
              className='widget-list'
              fields={headers}
              data={deliverables.content}
              columnRenderer={columnRenderer}
            />
            <Pagination
              totalPages={deliverables.totalPages}
              page={pageQuery.page}
              onPageChange={(page: number) => {
                setPageQuery({
                  ...pageQuery,
                  page: page,
                });
              }}
              onPageSizeChange={(pageSize: number) =>
                setPageQuery({ ...pageQuery, size: pageSize })
              }
            />
          </ListWrapper>
        </Column>
      </Column>
    </BaseWidget>
  );
};
