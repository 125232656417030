import React from 'react';

import './Drawer.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import { Row, Column } from './Flex';

type DivProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

type Orientation = 'bottom' | 'left' | 'right';

type DrawerProps = DivProps & {
  orientation: Orientation;
  open: boolean;
  onClose: VoidFunction;
};

export const Drawer = React.forwardRef<
  any,
  React.PropsWithChildren<DrawerProps>
>(
  (
    {
      className = '',
      orientation = 'right',
      open,
      onClose,
      children,
      ...otherProps
    }: React.PropsWithChildren<DrawerProps>,
    ref: any
  ) => {
    return (
      <Row
        ref={ref}
        className={`drawer pos-${orientation} ${
          open ? 'opened' : 'closed'
        } ${className}`}
        {...otherProps}
      >
        <FontAwesomeIcon
          icon={faChevronLeft}
          className="drawer-close-icon"
          onClick={onClose}
        />
        <Column className="drawer-body">{children}</Column>
      </Row>
    );
  }
);
