import React from 'react';

import debounce from 'lodash.debounce';

import { Column } from './Flex';

import { useScrollYPosition } from 'domain/hooks';

export interface InfiniteListProps {
  bottomThreshold: number;
  parentRef?: any;
  onBottomReach: VoidFunction;
}

const _checkEnd = debounce(
  (
    bottomThreshold: number = 0,
    onBottomReach: VoidFunction,
    scrollY: number,
    parentRef?: any
  ) => {
    if (!parentRef.current || parentRef.current.document) {
      // use window as ref
      if (
        parentRef.current.innerHeight + scrollY >=
        document.documentElement.offsetHeight - bottomThreshold
      ) {
        onBottomReach();
      }
    } else {
      if (
        parentRef.current.offsetHeight + scrollY >=
        parentRef.current.scrollHeight - bottomThreshold
      ) {
        onBottomReach();
      }
    }
  },
  100
);

export const InfiniteList = ({
  bottomThreshold,
  parentRef = { current: window },
  onBottomReach,
  children
}: React.PropsWithChildren<InfiniteListProps>) => {
  const scrollY = useScrollYPosition(parentRef.current);

  const checkEnd = React.useCallback(
    (scroll: number) => {
      _checkEnd(bottomThreshold, onBottomReach, scroll, parentRef);
    },
    [bottomThreshold, onBottomReach, parentRef]
  );

  React.useEffect(() => {
    checkEnd(scrollY);
  }, [scrollY, checkEnd, parentRef]);

  return <Column className='infinite-scroll-list'>{children}</Column>;
};
