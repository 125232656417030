import React from 'react';

import { faSave, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { Drawer, Column, Row, H, ButtonWithIcon } from 'components';

import { SideMenuContext } from 'domain/context';

import './Editor.scss';

interface EditorProps {
  open: boolean;
  loading?: boolean;
  title: string;
  className?: string;
  valid?: boolean;
  onConfirm?: VoidFunction;
  onCancel?: VoidFunction;
  onDelete?: VoidFunction;
}
export const Editor = (props: React.PropsWithChildren<EditorProps>) => {
  const { t } = useTranslation('common');

  const { open: sideMenuOpen } = React.useContext(SideMenuContext);

  return (
    <Drawer
      className={`editor-drawer side-menu-${sideMenuOpen ? 'open' : 'closed'} ${
        props.className ?? ''
      }`}
      orientation='bottom'
      open={props.open}
      onClose={() => {}}
    >
      <Column className={`editor ${props.loading ? 'loading' : ''}`}>
        <Row className='editor-header-row'>
          <H.H4>{props.title}</H.H4>
          <Row className='buttons-row'>
            {props.onConfirm && (
              <ButtonWithIcon icon={faSave} onClick={props.onConfirm} disabled={props.valid === false}>
                {t('common:components.Editor.buttons.save')}
              </ButtonWithIcon>
            )}
            {props.onCancel && (
              <ButtonWithIcon icon={faTimes} onClick={props.onCancel}>
                {t('common:components.Editor.buttons.cancel')}
              </ButtonWithIcon>
            )}
            {props.onDelete && (
              <ButtonWithIcon icon={faTrash} onClick={props.onDelete}>
                {t('common:components.Editor.buttons.delete')}
              </ButtonWithIcon>
            )}
          </Row>
        </Row>
        {props.children}
      </Column>
    </Drawer>
  );
};
