import React from 'react';

import './Textarea.scss';

export const Textarea = React.forwardRef(
  (
    props: React.DetailedHTMLProps<
      React.TextareaHTMLAttributes<HTMLTextAreaElement>,
      HTMLTextAreaElement
    >,
    ref: any
  ) => {
    const { className, ...otherProps } = props;

    return (
      <textarea className={`textarea ${className || ''}`} {...otherProps} />
    );
  }
);
