import i18next from 'i18next';
import i18nextBackend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

i18next
  .use(i18nextBackend)
  .use(initReactI18next)
  .init({
    // ns: ['common', 'auth', 'settings'], // Uncomment this to load all Namespaces on init
    nsSeparator: ':',
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    },
    fallbackLng: 'en',
    lng: 'en',
    debug: process.env.NODE_ENV === 'development',
    cleanCode: true,
    load: 'languageOnly',
    partialBundledLanguages: true,
    react: {
      wait: true
    }
  });

export { i18next };

export const Langs: { label: string; value: string }[] = [
  { label: 'ES', value: 'es' },
  { label: 'EN', value: 'en' }
];
