import { TFunction } from 'i18next';

export const DefaultWeight = -1;

export interface RoleUser {
  value: Role;
  weight: number;
}

export enum Role {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_CONTRACT_OFFICER = 'ROLE_CONTRACT_OFFICER',
  ROLE_TECHNICAL_OFFICER = 'ROLE_TECHNICAL_OFFICER',
  ROLE_CONTRACTOR = 'ROLE_CONTRACTOR',
  ROLE_CDS = 'ROLE_CDS',
  ROLE_REVIEWER = 'ROLE_REVIEWER',
  ROLE_ANONYMOUS = 'ROLE_ANONYMOUS',
}

export const Roles: RoleUser[] = [
  {
    value: Role.ROLE_ADMIN,
    weight: 100,
  },
  {
    value: Role.ROLE_CONTRACT_OFFICER,
    weight: 51,
  },
  {
    value: Role.ROLE_TECHNICAL_OFFICER,
    weight: 50,
  },
  {
    value: Role.ROLE_CDS,
    weight: 20,
  },
  {
    value: Role.ROLE_CONTRACTOR,
    weight: 11,
  },
  {
    value: Role.ROLE_REVIEWER,
    weight: 10,
  },
  {
    value: Role.ROLE_ANONYMOUS,
    weight: DefaultWeight,
  },
];

export const getRoles = (t: TFunction): (RoleUser & { label: string })[] => {
  return Roles.map((role) => ({
    ...role,
    label: t(`admin:users.roles.${role.value}`),
  }));
};

export interface User {
  id: number;
  username: string;
  email: string;
  roles: Role[];
  enabled: boolean;
  accountNonExpired: boolean;
  accountNonLocked: boolean;
  credentialsNonExpired: boolean;
  authorities: any[];
}

export interface UserUpdate extends Pick<User, 'username' | 'email'> {
  currentPassword?: string;
  password?: string;
}

export type NotificationChannel = 'EMAIL' | 'SLACK';

export interface SlackConfiguration {
	endpoint: string;
	channel: string;
}

export interface UserSettings {
  notificationChannels: NotificationChannel[];
  slackConfiguration: SlackConfiguration;
}

export interface ContractManager extends User {}

export interface ContractOfficer extends User {}
