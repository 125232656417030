import React from 'react';

import { useTranslation } from 'react-i18next';

import { Input, Form, LoadingButton, Link } from 'components';

import { LoginFormFormData } from 'domain/models';

import './LoginForm.scss';

export interface LoginFormProps {
  loading: boolean;
  onSubmit(formData: LoginFormFormData): void;
}

export const LoginForm = ({ loading, onSubmit }: LoginFormProps) => {
  const { t } = useTranslation(['auth', 'common']);

  const [formData, setFormData] = React.useState<LoginFormFormData>({
    username: '',
    password: '',
  });

  const intOnSubmit = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    evt.preventDefault();
    evt.stopPropagation();
    if (!submitDisabled()) {
      onSubmit(formData);
    }
  };

  const submitDisabled = React.useCallback((): boolean => {
    const { username, password } = formData;
    if (!username || !password) {
      return true;
    }

    if (username.length < 5 || password.length < 5) {
      return true;
    }

    if (loading) {
      return true;
    }

    return false;
  }, [loading, formData]);

  return (
    <Form className='login-form-container'>
      <label>
        {t('auth:loginForm.username.label')}
        <Input
          name='username'
          value={formData.username}
          onChange={({ target }) =>
            setFormData({ ...formData, username: target.value })
          }
        />
      </label>
      <label>
        {t('auth:loginForm.password.label')}
        <Input
          name='password'
          type='password'
          value={formData.password}
          onChange={({ target }) =>
            setFormData({ ...formData, password: target.value })
          }
        />
      </label>
      <label>
        <Link href="/user/lost-password">{t('auth:loginForm.passwordForget.label')}</Link>
      </label>
      <LoadingButton
        className='submit-button secondary'
        loading={loading}
        onClick={intOnSubmit}
        disabled={submitDisabled()}
        type='submit'
      >
        {t('common:actions.submit')}
      </LoadingButton>
    </Form>
  );
};
