import { createReducer } from 'deox';

import * as Actions from './root.actions';

import { RootStore, Notification, AllContractsOption } from 'domain/models';

export const initialState: RootStore = {
  notifications: [],
  rootContract: AllContractsOption,
};

export const rootReducer = createReducer(initialState, (handle) => [
  handle(Actions.pushNotification, (state, { payload }) => ({
    ...state,
    notifications: state.notifications.concat(
      Object.freeze({ ...payload, innerKey: Date.now() })
    ),
  })),
  handle(Actions.removeNotification, (state, { payload }) => {
    return {
      ...state,
      notifications: state.notifications.filter((n: Notification) => {
        return n.innerKey !== payload;
      }),
    };
  }),
  handle(Actions.setRootContract, (state, { payload }) => {
    return {
      ...state,
      rootContract: payload,
    };
  }),
]);
