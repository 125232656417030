import { CDSDataByDeliverableWidget } from './cds-data-by-deliverable/CDSDataByDeliverable';
import { CDSDeliverableWidget } from './cds-deliverable/CDSDeliverable';
import { CDSTodoListWidget } from './cds-todo-list/CDSTodoList';
import { ContractorDeliverableListWidget } from './contractor-deliverable-list/ContractorDeliverableList';
import { KpiMetricsWidget } from './kpi-metrics/KpiMetrics';

import { LastMonthContractsWidget } from './last-month-contracts/LastMonthContracts';
import { LastMonthTasksWidget } from './last-month-tasks/LastMonthTasks';
import { OverdueTasksWidget } from './overdue-tasks/OverdueTasks';
import { TasksByContractWidget } from './tasks-by-contract/TasksByContract';

import { WidgetConfiguration } from './widget.model';

export const WidgetsConfiguration: WidgetConfiguration[] = [
  {
    code: 'contractorDeliverableList',
    width: 12,
    type: 'list',
    Component: ContractorDeliverableListWidget,
  },
  {
    code: 'cdsTodoList',
    width: 6,
    type: 'list',
    Component: CDSTodoListWidget,
  },
  {
    code: 'cdsDeliverable',
    width: 12,
    type: 'list',
    Component: CDSDeliverableWidget,
  },
  {
    code: 'cdsDataByDeliverable',
    width: 6,
    type: 'list',
    Component: CDSDataByDeliverableWidget,
  },
  {
    code: 'lastMonthContracts',
    width: 6,
    type: 'barchart',
    Component: LastMonthContractsWidget,
  },
  {
    code: 'overdueTask',
    width: 6,
    type: 'list',
    Component: OverdueTasksWidget,
  },
  {
    code: 'tasksByContract',
    width: 6,
    type: 'list',
    Component: TasksByContractWidget,
  },
  {
    code: 'lastMonthTasks',
    width: 6,
    type: 'barchart',
    Component: LastMonthTasksWidget,
  },
  {
    code: 'deliverablesKpis',
    width: 6,
    type: 'custom',
    Component: KpiMetricsWidget,
  },
];
