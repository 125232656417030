import { ErrorResponse, HateoasResource } from 'domain/models';

// Esto se hace para poder mockear las funciones que se llaman dentro de otras funciones
// que están en el mismo módulo.
// @see https://stackoverflow.com/a/55193363
import * as CommonUtils from '.';

export const range = (length: number, from: number = 0): number[] =>
  Array.from(new Array(length), (_, i) => from + i);

export const assert = (
  condition: boolean,
  message: string = 'Assertion failed'
): void => {
  if (!condition) {
    throw new Error(message);
  }
};

export const mergeRefs = (...refs: any[]) => {
  const filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) return null;
  if (filteredRefs.length === 1) return filteredRefs[0];
  return (inst: any) => {
    for (const ref of filteredRefs) {
      if (typeof ref === 'function') {
        ref(inst);
      } else if (ref) {
        ref.current = inst;
      }
    }
  };
};
 
export function asSelect<T>(list?: { name: string, id: T }[]) {
  return list?.map(item => ({
    label: item.name,
    value: item.id,
  }));
}

export const toHateoasResource = <T extends any>(
  response: Pick<HateoasResource<T>, '_embedded' | '_links' | 'page'>,
  resourceName: string
): HateoasResource<T> => {
  return {
    ...response,
    _data: response._embedded[resourceName],
  };
};

export const toQueryParams = <T extends object>(params: T): string => {
  return Object.entries(params)
  .filter(([, value]) => value !== undefined)
  .reduce(
    (acc: string, [key, value]: [string, any]) => `${acc}&${key}=${value}`,
    ''
  );
};

export const downloadBlob = (filename: string, blob: Blob) => {
  const url = window.URL.createObjectURL(blob);
  CommonUtils.triggerLinkClick(url, filename);
};

export const urlToBlob = async (url: string): Promise<Blob> => {
  const response = await fetch(url);
  if (response.body?.constructor.name === 'Blob') {
    return await response.blob();
  }

  if (response.body?.constructor.name === 'ReadableStream') {
    const stream: ArrayBuffer = await response.arrayBuffer();
    return new Blob([new Uint8Array(stream, 0, stream.byteLength)]);
  }

  const text = await response.text();
  return new Blob([text]);
};

export const triggerLinkClick = (url: string, filename: string): void => {
  const a = document.createElement('a');
  a.href = url;
  a.download = `${filename}`;
  a.target = '_blank';
  document.body.appendChild(a);
  a.click();
  a.remove();
};

export const isError = (err: ErrorResponse | any): err is ErrorResponse => {
  if ('code' in err && 'message' in err) {
    return true;
  } else {
    return false;
  }
};

/**
 * Syntax sugar for:
 * ```
 *  setTimeout(() => {
 *    // do something
 *  }, time)
 * ```
 * Same effect as debounce
 *
 * @param time
 * @returns
 */
export const wait = (time: number): Promise<void> => {
  return new Promise((res) => {
    let tm = setTimeout(() => {
      res();
      clearTimeout(tm);
    }, time);
  });
};

export const strContains = (
  target: string,
  crit: string,
  ignoreCase = true
): boolean => {
  if (ignoreCase) {
    return target.toLocaleLowerCase().includes(crit.toLocaleLowerCase());
  } else {
    return target.includes(crit);
  }
};

export const arrayMoveMutate = <T>(
  array: T[],
  from: number,
  to: number
): void => {
  const startIndex = from < 0 ? array.length + from : from;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = to < 0 ? array.length + to : to;

    const [item] = array.splice(from, 1);
    array.splice(endIndex, 0, item);
  }
};

export const arrayMove = <T>(array: T[], from: number, to: number): T[] => {
  array = [...array];
  arrayMoveMutate(array, from, to);
  return array;
};
