import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useHistory } from 'react-router';
import { Row } from './Flex';

import './Link.scss';

export const Link = React.forwardRef(
  (
    props: React.DetailedHTMLProps<
      React.AnchorHTMLAttributes<HTMLAnchorElement>,
      HTMLAnchorElement
    >,
    ref: any
  ) => {
    const { className, children, ...otherProps } = props;

    return (
      <a className={`link ${className || ''}`} ref={ref} {...otherProps}>
        {children}
      </a>
    );
  }
);

interface DataLinkProps {
  relPath: string;
  title?: string;
  className?: string;
  onClick?: VoidFunction;
}
export const DataLink = React.forwardRef<
  any,
  React.PropsWithChildren<DataLinkProps>
>((props: React.PropsWithChildren<DataLinkProps>, ref: any) => {
  const { className, children, title, relPath, onClick } = props;

  const history = useHistory();

  const intOnClick = () => {
    if (onClick) {
      onClick();
    } else {
      history.push(relPath);
    }
  };

  return (
    <Row
      className={`data-link ${className || ''}`}
      ref={ref}
      title={title}
      onClick={intOnClick}
    >
      <span className='data-link-text'>{children}</span>
      <FontAwesomeIcon icon={faLink} className='data-link-icon' />
    </Row>
  );
});
