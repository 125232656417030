import React from 'react';

import {
  ResponsiveContainer,
  BarChart,
  YAxis,
  XAxis,
  CartesianGrid,
  Tooltip,
  Bar,
} from 'recharts';

import { BaseWidget, BaseWidgetTitle } from '../BaseWidget';

import { DateUnit } from 'domain/utils';
import { WidgetProps } from '../widget.model';
import { useHistoricalTasks } from 'domain/swr';

import './LastMonthTasks.scss';

const startDate = DateUnit.MONTH.traslate(new Date(), -1);
const now = new Date();

export const LastMonthTasksWidget = (props: WidgetProps) => {
  const [historicalTasks] = useHistoricalTasks(
    { page: 0, size: 999 },
    {
      fromEndDate: startDate.toISOString(),
      toEndDate: now.toISOString(),
    }
  );

  const data = React.useMemo(() => {
    const accumulator = Object.fromEntries(
      DateUnit.DAY.fill(startDate, now).map((day) => [
        DateUnit.DAY.atStart(day).toISOString(),
        0,
      ])
    );

    historicalTasks.content
      .filter((task) => task.endTime !== undefined)
      .forEach((task) => {
        const key = DateUnit.DAY.atStart(new Date(task.endTime!)).toISOString();
        accumulator[key]++;
      });

    return Object.entries(accumulator).reduce(
      (acc: any, [date, value]: [string, number]) => {
        return acc.concat({
          date,
          value,
        });
      },
      []
    );
  }, [historicalTasks.content]);

  return (
    <BaseWidget {...props}>
      <BaseWidgetTitle content='Tasks finished during last month' />
      <ResponsiveContainer width='100%' height={400}>
        <BarChart data={data}>
          <YAxis />
          <XAxis
            dataKey='date'
            tickFormatter={(value) => new Date(value).toLocaleDateString()}
          />
          <CartesianGrid strokeDasharray='3 3' />
          <Tooltip
            labelFormatter={(label) => new Date(label).toLocaleDateString()}
            formatter={(value: number, label: string) => [
              value,
              'Tasks finished',
            ]}
          />
          <Bar dataKey='value' fill='#941333' />
        </BarChart>
      </ResponsiveContainer>
    </BaseWidget>
  );
};
