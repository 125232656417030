import { Api } from 'domain/core';
import { ResponseAsArray } from 'domain/models';
import { toQueryParams } from 'domain/utils';
import useSWR from 'swr';

export type KpiMetrics = {
  outstanding: number;
  late: number;
  delivered: number;
  approved: number;
  rejected: number;
  total: number;
};

export const useKpiMetrics = ({
  contract_id,
  days,
}: {
  contract_id?: number;
  days: number;
}): ResponseAsArray<KpiMetrics> => {
  const url = `${Api.baseUrl}/users/me/kpi?${toQueryParams({
    contract_id,
    days,
  })}`;

  const { data, isValidating, error, mutate, revalidate } =
    useSWR<KpiMetrics>(url);

  return [data as KpiMetrics, isValidating, error, mutate, revalidate];
};
