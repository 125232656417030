import React from 'react';

import { WidgetProps } from '../widget.model';
import { useDeliverables, useECVs } from 'domain/swr';

import './CDSTodoList.scss';
import { BaseWidget, BaseWidgetTitle } from '../BaseWidget';
import { Column, DataLink, Row, Select } from 'components';
import {
  AppStore,
  Deliverable,
  DeliverableState,
  DeliverableType,
  SortDirection,
} from 'domain/models';
import { useSelector } from 'react-redux';
import {
  ActionCellValue,
  ActionIconContainer,
  DefaultCellValue,
  HeaderField,
  List,
  ListElementRenderer,
  ListHeader,
  ListWrapper,
  NoElements,
} from 'pages/shared';
import { asSelect } from 'domain/utils';
import { useHistory } from 'react-router';
import { formatISO } from 'date-fns';

const headers: HeaderField[] = [
  {
    code: 'name',
    label: 'Name',
    col: 4,
    sortable: false,
  },
  {
    code: 'end-date', // Only placeholder. This really does not exists in the backend.
    label: 'End date',
    col: 4,
    sortable: false,
  },
  {
    code: 'type',
    label: 'Type',
    col: 4,
    sortable: false,
  },
];

export const CDSTodoListWidget = (props: WidgetProps) => {
  const selectedContract = useSelector(
    (store: AppStore) => store.root.rootContract
  );

  const [selectedECVs, setSelectedECVs] = React.useState<
    { value: number; label: string }[]
  >([]);
  const [selectedTypes, setSelectedTypes] = React.useState<
    { value: string; label: string }[]
  >([]);

  const deliverableFilter = React.useMemo(() => {
    return {
      contractId: selectedContract?.id,
      ecv: selectedECVs.map((ecv) => ecv.value),
      types: selectedTypes.map((type) => type.value),
      states: ['FINISHED'] as DeliverableState[],
      sort: `endDate,DESC`,
    };
  }, [selectedContract, selectedECVs, selectedTypes]);

  const [ecvs] = useECVs({ page: 0, size: 999 });
  const [deliverables] = useDeliverables(deliverableFilter, {
    page: 0,
    size: 5,
  });

  const ecvOptions = React.useMemo(() => asSelect<number>(ecvs), [ecvs]);
  const typeOptions = [
    DeliverableType.DOCUMENTATION,
    DeliverableType.DATASET,
  ].map((d) => ({ label: d, value: d }));

  const columnRenderer: ListElementRenderer = (
    field: HeaderField,
    element: Deliverable
  ) => {
    switch (field.code) {
      case 'name':
        return (
          <DefaultCellValue title={element.name}>
            <DataLink
              relPath={`/app/contract/${element.contract}/deliverable/${element.id}`}
            >
              {element.name}
            </DataLink>
          </DefaultCellValue>
        );
      case 'end-date':
        const date = formatISO(new Date(element.deadline), {
          representation: 'date',
        });
        return <DefaultCellValue title={date}>{date}</DefaultCellValue>;

      case 'type':
        return (
          <DefaultCellValue title={element.type}>
            {element.type ?? '-'}
          </DefaultCellValue>
        );
      default:
        return null;
    }
  };

  return (
    <BaseWidget {...props}>
      <BaseWidgetTitle content='TODO List' />
      <Column className='cds-todo-list-container'>
        <Row className='cds-todo-list-selects'>
          <Select
            isMulti
            className='type-select'
            options={typeOptions}
            value={selectedTypes}
            onChange={setSelectedTypes}
          />
          <Select
            isMulti
            className='ecv-select'
            options={ecvOptions}
            value={selectedECVs}
            onChange={setSelectedECVs}
          />
        </Row>

        <Column className='cds-todo-list-wrapper'>
          {deliverables?.content !== undefined ? (
            <>
              <ListWrapper>
                <NoElements>
                  There are no deliverables that matches current filter
                </NoElements>

                <ListHeader fields={headers} onSortChange={(sort) => {}} />
                <List
                  className='widget-list'
                  fields={headers}
                  decorator={false}
                  data={deliverables.content}
                  columnRenderer={columnRenderer}
                />
              </ListWrapper>
            </>
          ) : (
            <div className='no-deliverables'>
              There are no deliverables that matches current filter
            </div>
          )}
        </Column>
      </Column>
    </BaseWidget>
  );
};
