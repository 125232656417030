import React from 'react';

import { WidgetProps } from '../widget.model';
import { useDeliverables, useDocument } from 'domain/swr';

import './CDSDataByDeliverable.scss';
import { BaseWidget, BaseWidgetTitle } from '../BaseWidget';
import { Column, Select } from 'components';
import { AppStore } from 'domain/models';
import { DeliverablesFilter } from 'pages/app/deliverables/DeliverablesListFilter';
import { useSelector } from 'react-redux';
import { useUISchema } from 'domain/swr/useWorkflows';
import { CdsTable } from 'components/CDSTable';
import { getPages } from 'domain/utils/documents.utils';

export const CDSDataByDeliverableWidget = (props: WidgetProps) => {

  const CDSInfoWorkflows = ['metadataWorkflow'];  // Workflows containing metadata for CDS
  const CDSDocumentId = "form-document";

  const selectedContract = useSelector(
    (store: AppStore) => store.root.rootContract
  );

  const [ deliverableFilter, setDeliverableFilter ] = React.useState<DeliverablesFilter>({
    contractId: selectedContract?.id,
    workflows: CDSInfoWorkflows,
    states: ['STARTED', 'FINISHED', 'REJECTED'],
  });

  const [ deliverables ] = useDeliverables(deliverableFilter);

  const [ selectedDeliverable, setSeletedDeliverable ] = React.useState(deliverables?.content && deliverables.content.length > 0 ? {
      id: deliverables.content[0].id,
      name: deliverables.content[0].name,
  } : null)
  

  const selectOptions = React.useMemo(() => {
    if (deliverables?.content === undefined) {
      return [];
    }
    return deliverables?.content?.map((c) => ({ label: c.name, value: c.id }));
  }, [deliverables]);

  const deliverable = selectedDeliverable ? deliverables?.content?.find((d) => d.id === selectedDeliverable.id) : undefined;
  const contractId = deliverable?.contract;
  const deliverableId = deliverable?.id;
  const [document] = useDocument(contractId, deliverableId, CDSDocumentId);  
  const pages = document? getPages(document) : [];

  return (
    <BaseWidget {...props}>
    <BaseWidgetTitle content='Scientific information by deliverable' />
      <Column className='cds-data-by-deliverable-container'>
        <Select
          className='deliverable-select'
          options={selectOptions}
          value={{
            label: selectedDeliverable?.name,
            value: selectedDeliverable?.id,
          }}
          onChange={({ label, value }: { label: string, value: number }) =>
            setSeletedDeliverable({ id: value, name: label })
          }
        />
      
        <Column className='cds-data-by-deliverable-wrapper'>
          {(deliverables?.content !== undefined && selectedDeliverable !== undefined && pages !== undefined) ? (<>
              <CdsTable pages={pages} />
          </>) : 
            (<div className='no-deliverables'>There are no deliverables</div>)}
        </Column>
      </Column>
    </BaseWidget>
  );
};
