import React from 'react';
import { createPortal } from 'react-dom';

export const SecurePortal = ({
  id,
  children,
}: React.PropsWithChildren<{ id: string }>) => {
  const [container, setContainer] = React.useState<HTMLDivElement | null>(null);

  React.useEffect(() => {
    let _container = document.getElementById(id) as HTMLDivElement;

    let interval: any = null;

    if (_container) {
      setContainer(_container);
    } else {
      interval = setInterval(() => {
        _container = document.getElementById(id) as HTMLDivElement;
        if (_container) {
          setContainer(_container);
          clearInterval(interval);
        }
      }, 250);
    }

    return () => {
      clearInterval(interval);
    };
  }, [id]);

  if (container) {
    return createPortal(children, container);
  }
  return null;
};
