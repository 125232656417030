import React from 'react';

import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Grid, GridItem, Row } from 'components';

import { SortInfo } from 'domain/models';

import './ListHeader.scss';

export type HeaderField = {
  label: string | React.ReactElement;
  code: string;
  sortable: boolean;
  col: number;
  className?: string;
};

interface ListHeaderProps {
  fields: HeaderField[];
  sortField?: SortInfo['sortField'];
  sortDir?: SortInfo['sortDir'];
  className?: string;
  onSortChange(sortInfo: SortInfo): void;
}
export const ListHeader = ({
  fields,
  sortDir,
  sortField,
  className = '',
  onSortChange,
}: ListHeaderProps) => {
  const intOnSortChange = (field: HeaderField) => {
    if (field.sortable) {
      if (sortField === field.code) {
        if (sortDir === 'ASC') {
          onSortChange({ sortField: field.code, sortDir: 'DESC' });
        } else {
          onSortChange({ sortField: field.code, sortDir: 'ASC' });
        }
      } else {
        onSortChange({ sortField: field.code, sortDir: 'ASC' });
      }
    }
  };

  const SortIcon = ({ field }: { field: HeaderField }) => {
    if (field.sortable && field.code === sortField) {
      if (sortDir === 'ASC') {
        return (
          <FontAwesomeIcon
            icon={faChevronDown}
            className='list-header-field-icon'
          />
        );
      } else {
        return (
          <FontAwesomeIcon
            icon={faChevronUp}
            className='list-header-field-icon'
          />
        );
      }
    }
    return null;
  };



  const cols = React.useMemo(() => {
    return (
      fields.reduce((acc: number, curr: HeaderField) => acc + curr.col, 0) || 12
    );
  }, [fields]);

  return (
    <Grid className={`list-header-grid ${className}`} columns={cols}>
      {fields.map((field) => (
        <GridItem
          key={field.code}
          className={`list-header-field-container ${field.code} ${
            field.className ?? ''
          } ${field.code === sortField ? 'active' : ''} ${
            field.sortable ? 'sortable' : ''
          }`}
          col={field.col}
        >
          <Row
            className='list-header-field'
            onClick={() => intOnSortChange(field)}
            title={'string' === typeof field.label ? field.label : ''}
          >
            <span className='list-header-field-name'>{field.label}</span>
            <SortIcon field={field} />
          </Row>
        </GridItem>
      ))}
    </Grid>
  );
};
