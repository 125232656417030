import useSwr from 'swr';

import {
  ECV,
  PageQuery,
  ResponseAsArray,
} from 'domain/models';
import { Api } from 'domain/core';
import { BaseFetcher, SWRBaseFetcher } from './BaseFetcher';
import { toQueryParams } from 'domain/utils';

export const useECVs = (
  pageInfo: PageQuery = { page: 0, size: 50 }
): ResponseAsArray<ECV[]> => {
  const queryString = toQueryParams(pageInfo);

  const url = queryString
    ? `${Api.baseUrl}/rest/ecvs?${queryString}`
    : `${Api.baseUrl}/rest/ecvs`;

  const { data, isValidating, error, revalidate } = useSwr(url);

  return [data, isValidating, error, , revalidate];
};

export const createECV = (ecv: Partial<ECV>) => {
  const { id, ...props } = ecv;

  return new SWRBaseFetcher().make(`${Api.baseUrl}/rest/ecvs`, {
    method: 'POST',
    body: JSON.stringify(props),
  });
};

export const updateEcv = (ecv: Partial<ECV>) => {
  const { id, ...updatableProps } = ecv;

  return new SWRBaseFetcher().make(`${Api.baseUrl}/rest/ecvs/${id}`, {
    method: 'PATCH',
    body: JSON.stringify(updatableProps),
  });
};

export const removeEcv = (ecv: Partial<ECV>) => {
  const { id } = ecv;

  if (id) {
    return new BaseFetcher().make(`${Api.baseUrl}/rest/ecvs/${id}`, {
      method: 'DELETE',
    });
  } else {
    throw new Error('Cannot remove ECV');
  }
};
