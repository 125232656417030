import { update } from 'ramda';
import React from 'react';

import { Column, Row } from './Flex';

import './Stepper.scss';

type Step = {
  label: string;
  code: string;
  enabled: boolean;
};
interface StepperProps {
  steps: Step[];
  activeStep: string;
  className?: string;
  onStepClick(step: Step): void;
}
export const Stepper = ({
  activeStep,
  className = '',
  steps = [],
  onStepClick,
}: StepperProps) => {
  const StepComponent = ({ step, index }: { step: Step; index: number }) => {
    return (
      <Column
        className={`step ${step.enabled ? 'enabled' : 'disabled'} ${
          step.code === activeStep ? 'active' : ''
        }`}
        onClick={() => (step.enabled ? onStepClick(step) : () => {})}
      >
        <Row className='step-index'>{index + 1}</Row>
        <Row className='step-label'>{step.label}</Row>
      </Column>
    );
  };

  return (
    <Row className={`stepper ${className}`}>
      {steps.map((step, index) => (
        <StepComponent key={step.code} step={step} index={index} />
      ))}
    </Row>
  );
};

export const useStepper = (steps: Step[], initialActiveStep: string) => {
  const [activeStep, setActiveStep] = React.useState(initialActiveStep);
  const [_steps, setSteps] = React.useState<Step[]>(steps);

  const orderArr = React.useRef<string[]>([]);
  React.useEffect(() => {
    orderArr.current = _steps.map((s) => s.code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const activateNext = () => {
    const currIdx = orderArr.current.indexOf(activeStep);
    if (currIdx !== _steps.length - 1) {
      const idx = currIdx + 1;
      const nextStep = orderArr.current[idx];
      const fullStep = _steps[idx];
      if (nextStep && fullStep) {
        setSteps(update(idx, { ...fullStep, enabled: true }, _steps));
        setActiveStep(nextStep);
      }
    }
  };

  const activatePrev = () => {
    const currIdx = orderArr.current.indexOf(activeStep);
    if (currIdx !== 0) {
      const idx = currIdx - 1;
      const nextStep = orderArr.current[idx];
      const fullStep = _steps[idx];
      if (nextStep && fullStep) {
        setSteps(update(idx, { ...fullStep, enabled: true }, _steps));
        setActiveStep(nextStep);
      }
    }
  };

  const setEnabled = (stepCode: string, enable: boolean) => {
    setSteps(
      _steps.map((s) => {
        if (s.code === stepCode) {
          return {
            ...s,
            enabled: enable,
          };
        }
        return s;
      })
    );
  };

  const disable = (stepCode: string) => {
    setEnabled(stepCode, false);
  };

  const enable = (stepCode: string) => {
    setEnabled(stepCode, true);
  };

  return {
    activateNext,
    activatePrev,
    disable,
    enable,
    activeStep,
    setActiveStep,
    steps: _steps,
  };
};
