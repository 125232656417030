import { Input, Row, Select } from 'components';
import { ECV, Family } from 'domain/models';
import { strContains } from 'domain/utils';
import React from 'react';

import './EcvFilter.scss';

const ALL_OPTION = {
  label: 'All',
  value: null,
};

export type IEcvFilter = {
  name?: string;
  group?: string;
  family?: Family;
};
interface EcvFilterProps {
  ecvs: ECV[];
  filters: IEcvFilter;
  onChange(filter: IEcvFilter): void;
}
export const EcvFilter = (props: EcvFilterProps) => {
  const { groups, families } = React.useMemo(() => {
    const r = props.ecvs.reduce(
      (
        { groups, families }: { groups: string[]; families: Family[] },
        curr: ECV
      ): { groups: string[]; families: Family[] } => {
        return {
          groups: groups.concat(curr.group),
          families: families.concat(curr.family),
        };
      },
      { groups: [], families: [] }
    );
    return {
      groups: Array.from(new Set(r.groups.filter((g) => !!g))),
      families: Array.from(new Set(r.families.filter((f) => !!f))),
    };
  }, [props.ecvs]);

  const intOnChange = <K extends keyof IEcvFilter>(
    key: K,
    value: IEcvFilter[K]
  ): void => {
    const newFilters = {
      ...props.filters,
      [key]: value,
    };
    props.onChange(newFilters);
  };

  return (
    <Row className='ecv-filter-container'>
      <label className='ecv-filter-label'>
        Variable
        <Input
          type='text'
          className='ecv-filter-input'
          value={props.filters.name || ''}
          onChange={({ target }) => intOnChange('name', target.value)}
        />
      </label>

      <label className='ecv-filter-label'>
        Family
        <Select
          className='ecv-filter-select'
          options={[ALL_OPTION as any].concat(
            families.map((f) => ({ label: f, value: f }))
          )}
          value={{ label: props.filters.family, value: props.filters.family }}
          onChange={({ value }: { value: Family }) =>
            intOnChange('family', value)
          }
        />
      </label>

      <label className='ecv-filter-label'>
        Group
        <Select
          className='ecv-filter-select'
          options={[ALL_OPTION as any].concat(
            groups.map((g) => ({ label: g, value: g }))
          )}
          value={{ label: props.filters.group, value: props.filters.group }}
          onChange={({ value }: { value: string }) =>
            intOnChange('group', value)
          }
        />
      </label>
    </Row>
  );
};

export const filterEcvs = <T extends ECV>(
  ecvs: T[],
  filter: IEcvFilter
): T[] => {
  return ecvs.filter((ecv) => {
    let fName = false,
      fGroup = false,
      fFamily = false;
    if (filter.name) {
      if (strContains(ecv.name, filter.name)) {
        fName = true;
      }
    } else {
      fName = true;
    }

    if (filter.family) {
      if (ecv.family === filter.family) {
        fFamily = true;
      }
    } else {
      fFamily = true;
    }

    if (filter.group) {
      if (ecv.group === filter.group) {
        fGroup = true;
      }
    } else {
      fGroup = true;
    }

    return fName && fFamily && fGroup;
  });
};
