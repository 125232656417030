import React from 'react';

import { useHistory } from 'react-router';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Column, DataLink } from 'components';

import {
  ActionCellValue,
  ActionIconContainer,
  DefaultCellValue,
  HeaderField,
  List,
  ListElementRenderer,
  ListHeader,
  NoElements,
} from 'pages/shared/list';

import { BaseWidget, BaseWidgetTitle } from '../BaseWidget';

import { TaskList } from 'domain/models';
import { useTasks } from 'domain/swr';
import { WidgetProps } from '../widget.model';

import './OverdueTasks.scss';

const now = new Date().toISOString();

const headers: HeaderField[] = [
  {
    code: 'name',
    label: 'Name',
    col: 4,
    sortable: false,
  },
  {
    code: 'deliverable',
    label: 'Deliverable',
    col: 4,
    sortable: false,
  },
  {
    code: 'deadline',
    label: 'Deadline',
    col: 3,
    sortable: false,
  },
  {
    code: 'action',
    label: '',
    col: 1,
    sortable: false,
  },
];

export const OverdueTasksWidget = (props: WidgetProps) => {
  const history = useHistory();

  const [overdueTasks] = useTasks(
    { page: 0, size: 5 },
    {
      toDeadline: now,
    }
  );

  const columnRenderer: ListElementRenderer = (
    field: HeaderField,
    element: TaskList
  ) => {
    switch (field.code) {
      case 'deliverable':
        return (
          <DefaultCellValue title={element.deliverable.name}>
            <DataLink
              relPath={`/app/contract/${element.deliverable.contract}/deliverable/${element.deliverable.id}`}
            >
              {element.deliverable.name}
            </DataLink>
          </DefaultCellValue>
        );
      case 'action':
        return (
          <ActionCellValue>
            <ActionIconContainer
              onClick={() => history.push(`/app/task/${element.id}`)}
            >
              <FontAwesomeIcon
                icon={faArrowRight}
                className='action-icon'
              ></FontAwesomeIcon>
            </ActionIconContainer>
          </ActionCellValue>
        );
      default: return null; 

    }
  };

  return (
    <BaseWidget {...props}>
      <BaseWidgetTitle content='Overdue tasks' />
      <Column>
        <NoElements>There are no overdue tasks</NoElements>
        <ListHeader fields={headers} onSortChange={() => {}} />
        <List
          className='widget-list'
          data={overdueTasks.content}
          decorator
          fields={headers}
          columnRenderer={columnRenderer}
        />
      </Column>
    </BaseWidget>
  );
};
