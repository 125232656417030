import { createActionCreator } from 'deox';

import { Notification, RootContract } from 'domain/models';

export const PUSH_NOTIFICATION = '[Root] :: Add notification';

export const REMOVE_NOTIFICATION = '[Root] :: Remove Notification';

export const SET_ROOT_CONTRACT = '[Root] :: Set root contract';

export const pushNotification = createActionCreator(
  PUSH_NOTIFICATION,
  (resolve) => (notification: Omit<Notification, 'innerKey'>) =>
    resolve(notification)
);

export const removeNotification = createActionCreator(
  REMOVE_NOTIFICATION,
  (resolve) => (key: number) => resolve(key)
);

export const setRootContract = createActionCreator(
  SET_ROOT_CONTRACT,
  (resolve) => (contract: RootContract) => resolve(contract)
);
