import React from 'react';

import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { BaseWidget, BaseWidgetTitle } from '../BaseWidget';

import { DateUnit } from 'domain/utils';
import { WidgetProps } from '../widget.model';
import { useContracts } from 'domain/swr';

import './LastMonthContracts.scss';

const startDate = DateUnit.MONTH.traslate(new Date(), -1);
const now = new Date();

export const LastMonthContractsWidget = (props: WidgetProps) => {
  const [contracts] = useContracts(
    { page: 0, size: 999 },
    {
      fromStartDate: startDate.toISOString(),
      toStartDate: now.toISOString(),
    }
  );

  const data = React.useMemo(() => {
    const accumulator = Object.fromEntries(
      DateUnit.DAY.fill(startDate, now).map((day) => [
        DateUnit.DAY.atStart(day).toISOString(),
        0,
      ])
    );

    contracts.content
      .filter((contract) => contract.startDate !== undefined)
      .forEach((contract) => {
        const key = DateUnit.DAY.atStart(
          new Date(contract.startDate!)
        ).toISOString();
        accumulator[key]++;
      });

    return Object.entries(accumulator).reduce(
      (acc: any, [date, value]: [string, number]) => {
        return acc.concat({
          date,
          value,
        });
      },
      []
    );
  }, [contracts]);

  return (
    <BaseWidget {...props}>
      <BaseWidgetTitle content='Started contracts last month' />
      <ResponsiveContainer width='100%' height={400}>
        <BarChart data={data}>
          <YAxis />
          <XAxis
            dataKey='date'
            tickFormatter={(value) => new Date(value).toLocaleDateString()}
          />
          <CartesianGrid strokeDasharray='3 3' />
          <Tooltip
            labelFormatter={(label) => new Date(label).toLocaleDateString()}
            formatter={(value: number, label: string) => [
              value,
              'Contracts started',
            ]}
          />
          <Bar dataKey='value' fill='#941333' />
        </BarChart>
      </ResponsiveContainer>
    </BaseWidget>
  );
};
